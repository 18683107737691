import { apiCall } from "../client/apiCall";
import {
  assesmentEndpoints,
  baseUrl,
  reportEndpoints,
} from "../constant/constant";
import {
  infraMapReportPayloadInterface,
  uploadTranscriptMapModel,
  uploadTranscriptSummaryModel,
} from "../interface/infraMapModel";
import {
  postSummaryModel,
  uploadTranscriptModel,
} from "../interface/WafrReportModel";

interface data {
  url: string;
  method: string;
  data: any;
}

export async function getDropDownProjects(body: any) {
  let organiztionId = body.organizationId;
  let verticalId = body.verticalId;
  let response = await apiCall(
    baseUrl + `/connectors/projects/${organiztionId}/list/${verticalId}`,
    "GET"
  );
  return response;
}

export async function getProjectReportData(body: any) {
  let response = await apiCall(
    baseUrl + reportEndpoints.getReport,
    "POST",
    body
  );

  return response;
}

export async function deleteDropDownOptionsApi(body: any) {
  let id = {
    projectId: body,
  };

  let response = await apiCall(
    baseUrl + reportEndpoints.deleteReport,
    "POST",
    id
  );

  return response;
}

export async function getDownloadReport(data:any) {
    let response = await apiCall(baseUrl + reportEndpoints.downloadReport, "POST", data)
    return response
}

//PC_App_07, PC_App_08 - Api call to fetch report data
export async function getInfraMapReportDetails(
  payload: infraMapReportPayloadInterface
) {
  let response = await apiCall(baseUrl + `/report`, "POST", payload);
  return response;
}

export async function postUploadedTranscriptData(
  payload: uploadTranscriptModel
) {
  let response = await apiCall(
    baseUrl + `/assessments/uploadTranscript`,
    "POST",
    payload
  );
  return response;
}

export async function postSummaryData(payload: postSummaryModel) {
  let response = await apiCall(
    baseUrl + `/connectors/postSummary`,
    "POST",
    payload
  );

  return response;
}

export async function getRegeneratedSummary(payload: any) {
  let response = await apiCall(
    baseUrl + `/assessments/regenerateSummary`,
    "POST",
    payload
  );
  return response;
}

export async function postUpdatedRecommendationsData(payload: any) {
  let response = await apiCall(
    baseUrl + `/connectors/updateRecommendation`,
    "POST",
    payload
  );
  return response;
}

export async function postSessionData(payload: any) {
  let response = await apiCall(
    baseUrl + `/connectors/postAttributeDetails`,
    "POST",
    payload
  );
  return response;
}

export async function postSelectionRecommendationsData(payload: any) {
  let response = await apiCall(
    baseUrl + `/assessments/postSelectedAwsRecommendations`,
    "POST",
    payload
  );
  return response;
}

export async function deleteSessionData(payload: {
  attributeMasterId: string;
}) {
  let response = await apiCall(
    baseUrl + `/connectors/deleteAttribute`,
    "POST",
    payload
  );
  return response;
}
export async function getSasToken(permission = "w") {
  let body = {
    permission: permission,
  };

  let response = await apiCall(
    baseUrl + assesmentEndpoints.sasToken,
    "POST",
    body
  );
  return response;
}

export async function postCostBreakdownData(payload: any) {
  let response = await apiCall(
    baseUrl + `/report/postCostBreakdownDetails`,
    "POST",
    payload
  );
  return response;
}

export async function postMapCostBreakdown(payload: any) {
  let response = await apiCall(baseUrl + `/report/costBreakdown`,"POST", payload);
  return response;
}
export async function PostRecommendedArchitecture(payload: any) {
  let response = await apiCall(
    baseUrl + `/report/postRecommendedArchitecture`,
    "POST",
    payload
  );
  return response;
}

export async function postUploadedSummaryTranscriptData(payload: uploadTranscriptSummaryModel) {
  let response = await apiCall(
    baseUrl + `/assessments/uploadTranscriptSummary`,
    "POST",
    payload
  );
  return response;
}
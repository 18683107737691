import { useEffect, useState } from "react";
import { getSasToken } from "../service/ReportApi";
import moment from "moment";
import React from "react";

const AwsMdpwDowloadReport = (props: any) => {
    const reportData = props.props

    const migrationPlanData = reportData.migrationPlan

    const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<any>({ minDate: new Date(), weekNumber: 1 });
    const [weeks, setWeeks] = useState<string[]>([]);

    useEffect(() => {
        const weekData = updateWeeks();
        setMinDateAndRoundValue(weekData);
    }, [migrationPlanData]);

    const extractDatesFromProcesses = (data: any) => {
        let datesArray: string[] = [];

        data?.forEach((phase: any) => {
            phase.processTimeLine?.forEach((process: any) => {
                let startDate = moment(process.startWeek);
                let endDate = moment(process.endWeek);
                datesArray.push(startDate.format("YYYY-MM-DD"));
                datesArray.push(endDate.format("YYYY-MM-DD"));
            });
        });
        return datesArray;
    };

    const updateWeeks = () => {
        const allDates = extractDatesFromProcesses(migrationPlanData);

        const uniqueDates = Array.from(new Set(allDates));
        const dateObjects: Date[] = uniqueDates?.map((date) => new Date(date));

        let minDate = new Date(dateObjects[0]);
        let maxDate = new Date(dateObjects[0]);

        dateObjects.forEach((date) => {
            if (date < minDate) minDate = new Date(date);
            if (date > maxDate) maxDate = new Date(date);
        });

        let weeks: string[] = [];
        let currentDate: Date = new Date(minDate);
        const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
        // const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
        const weeksBetween = getWeekIndex(maxDate, minDate);

        let roundedIncrement;

        if (weeksBetween % 4 != 0) {
            roundedIncrement = Math.ceil(weeksBetween / 4)
        } else {
            roundedIncrement = Math.round(weeksBetween / 4);
        }

        let weekNumber: number
        if (roundedIncrement == 0) {
            roundedIncrement = 1
            weekNumber = roundedIncrement
        } else {
            weekNumber = roundedIncrement
        }

        for (let i = 1; i <= 4; i++) {
            weeks.push(`week ${weekNumber}`);
            currentDate.setDate(currentDate.getDate() + 7);
            weekNumber += roundedIncrement;
        }

        setWeeks(weeks);
        return { "minDate": minDate, "weekNumber": roundedIncrement };
    };

    const getWeekIndex = (date: Date, minDate: Date): number => {
        const startOfWeek = new Date(minDate);
        const targetDate = new Date(date);

        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        targetDate.setDate(targetDate.getDate() - targetDate.getDay());

        const weeksDiff = Math.round((targetDate.getTime() - startOfWeek.getTime()) / (7 * 24 * 60 * 60 * 1000));

        return weeksDiff >= 0 ? weeksDiff + 1 : 0;
    };

    const getWeekRange = (startDate: Date, endDate: Date, process: any, minDate: Date, weekNumber: number): any => {
        const startWeekIndex = getWeekIndex(startDate, minDate);
        const endWeekIndex = getWeekIndex(endDate, minDate);

        if (startWeekIndex !== -1 && endWeekIndex !== -1) {
            const totalBlocks = 4;
            const weeksPerBlock = weekNumber;

            const startBlock = Math.floor((startWeekIndex - 1) / weeksPerBlock);
            const endBlock = Math.floor((endWeekIndex - 1) / weeksPerBlock);

            const marginLeft = (startBlock / totalBlocks) * 100;
            const marginRight = ((totalBlocks - endBlock - 1) / totalBlocks) * 100;

            if (startWeekIndex === endWeekIndex) {
                return { "weekRange": `Week ${startWeekIndex}`, "marginLeft": `${marginLeft}%`, "marginRight": `${marginRight}%` };
            } else {
                return { "weekRange": `Week ${startWeekIndex} - Week ${endWeekIndex}`, "marginLeft": `${marginLeft}%`, "marginRight": `${marginRight}%` };
            }
        } else {
            return {};
        }
    };

    const handleBindingWeekRange = (process: any, index: any) => {
        const startDate = new Date(process.startWeek);
        const endDate = new Date(process.endWeek);

        const weekData = getWeekRange(startDate, endDate, process, minDateAndRoundValue.minDate, minDateAndRoundValue.weekNumber);


        return (
            <td colSpan={4} style={{ width: '58%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 600, color: '#242424', lineHeight: '1.5' }}>
                <div style={{ borderLeft: '4px solid #0F7FAF', borderRadius: '5px', backgroundColor: '#F1FBFF', padding: '10px 16px' }}>
                    <span>
                        {weekData.weekRange}
                    </span>
                </div>
            </td>
        );
    }

    const ExecutiveSummary = () => {

        const executiveSummary = reportData?.summary?.flatMap((s: any) => s)
            .find((item: any) => item.attributeName === "Executive Summary")?.attributeValue || "";

        const sessions = reportData?.session ? reportData?.session?.map((session: any) => ({
            title: session?.find((attr: any) => attr.attributeName.includes("Session Title"))?.attributeValue,
            description: session?.find((attr: any) => attr.attributeName.includes("Session Description"))?.attributeValue
        })) : [];

        return (
            <>

                {/*Executive Summary Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>

                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Executive Summary</h5>
                </div >
                {/*Executive Summary Header  ends here */}
                <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                    {/* Main header style */}
                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                        <div dangerouslySetInnerHTML={{ __html: executiveSummary }} />
                    </p >
                    {sessions?.map((session: any, index: any) => (
                        <div key={index}>
                            <h2 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                                {session.title} :
                            </h2>
                            <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                <div dangerouslySetInnerHTML={{ __html: session.description }} />
                            </p>
                        </div>
                    ))}
                </div >
            </>
        )
    }

    const BusinessOverview = () => {

        const businessOverview = reportData?.summary?.flatMap((s: any) => s)
            .find((item: any) => item.attributeName === "Business Overview")?.attributeValue || "";

        return (
            <>
                {/*Business Overview Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Business Overview</h5>
                </div>
                {/*Business Overview Header  ends here */}
                <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                    {/* Main header style */}
                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 400, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                        <div dangerouslySetInnerHTML={{ __html: businessOverview }} />
                    </p>
                </div>
            </>
        )
    }

    const BusinessFlow = () => {
        const [sasToken, setSasToken] = useState('');
    
        const businessFlows = reportData?.businessFlow?.map((flow:any) => ({
            name: flow.find((attr:any) => attr.attributeName === "Business Flow Name")?.attributeValue,
            bloburl: flow.find((attr:any) => attr.attributeName === "Business Flow URL")?.attributeValue,
            description: flow.find((attr:any) => attr.attributeName === "Business Flow Description")?.attributeValue
        })) || [];
    
        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };
    
            fetchSasToken();
        }, []);
    
        const generateImageUrl = (blobUrl:any) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };
    
        return (
            <>
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Business Flow
                    </h5>
                </div>
                {businessFlows.map((flow:any, index:any) => (
                    <div key={index} style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                        <h2 style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            {flow.name}
                        </h2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {flow.bloburl && (
                                <img src={generateImageUrl(flow.bloburl)} alt={`Business Flow ${index + 1}`} style={{ maxWidth: "1441px", maxHeight: "950px" }} />
                            )}
                        </div>
                        <h3 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            Business Flow Description
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: flow.description || "" }} style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }} />
                    </div>
                ))}
            </>
        );
    }

    const WorkFlow = () => {
        const [sasToken, setSasToken] = useState('');
    
        const workFlows = reportData?.workFlow?.map((flow:any) => ({
            name: flow.find((attr:any) => attr.attributeName === "Workflow Component Name")?.attributeValue,
            bloburl: flow.find((attr:any) => attr.attributeName === "Workflow Component URL")?.attributeValue,
            description: flow.find((attr:any) => attr.attributeName === "Workflow Component Description")?.attributeValue
        })) || [];
    
        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };
    
            fetchSasToken();
        }, []);
    
        const generateImageUrl = (blobUrl:any) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };
    
        return (
            <>
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Workflow Components
                    </h5>
                </div>
                {workFlows.map((flow:any, index:any) => (
                    <div key={index} style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                        <h2 style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            {flow.name}
                        </h2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {flow.bloburl && (
                                <img src={generateImageUrl(flow.bloburl)} alt={`Workflow ${index + 1}`} style={{ maxWidth: "1441px", maxHeight: "950px" }} />
                            )}
                        </div>
                        <h3 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            Workflow Description
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: flow.description }} style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }} />
                    </div>
                ))}
            </>
        );
    }

    const CurrentStateArchitecture = () => {
        const [sasToken, setSasToken] = useState('');
    
        const architectures = reportData?.currentStateArchitecture?.map((arch:any) => ({
            name: arch.find((attr:any) => attr.attributeName === "Current State Architecture Name")?.attributeValue,
            bloburl: arch.find((attr:any) => attr.attributeName === "Current State Architecture URL")?.attributeValue,
            description: arch.find((attr:any) => attr.attributeName === "Current State Architecture Description")?.attributeValue
        })) || [];
    
        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };

            fetchSasToken();
        }, []);

        const generateImageUrl = (blobUrl:any) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        return (
            <>
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Current State Architecture
                    </h5>
                </div>
                {architectures.map((arch:any, index:any) => (
                    <div key={index} style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                        <h2 style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            {arch.name}
                        </h2>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {arch.bloburl && (
                                <img src={generateImageUrl(arch.bloburl)} alt={`Architecture ${index + 1}`} style={{ maxWidth: "1441px", maxHeight: "950px" }} />
                            )}
                        </div>
                        <h3 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            Architecture Description
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: arch.description }} style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }} />
                    </div>
                ))}
            </>
        );
    }


    const FutureStateArchitecture = () => {
        const [sasToken, setSasToken] = useState('');

        const architectures = reportData?.futureStateArchitecture ? reportData.futureStateArchitecture.map((architectureArray: any) => ({
            name: architectureArray?.find((attr: any) => attr.attributeName === "Architecture Name")?.attributeValue,
            blobUrl: architectureArray?.find((attr: any) => attr.attributeName === "Architecture Blob URL")?.attributeValue,
        })) : [];

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                    console.error("Error fetching SAS token:", error);
                }
            };

            fetchSasToken();
        }, []);

        const generateImageUrl = (blobUrl: string) => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        return (
            <div>
                {/* Only show this header once, not before each architecture */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Future State Architecture
                    </h5>
                </div>

                {architectures?.map((architecture: any, index: any) => (
                    <div style={{ width: '98%', float: 'left', margin: '1% 0%', pageBreakInside: "avoid" }}>

                        {/* Architecture Header */}
                        <h2 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>

                            {architecture.name}
                        </h2>
                        {/* Architecture Image */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* <img
                                src={generateImageUrl(architecture.blobUrl)}
                                width={495} height={354}
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    marginTop: "5%",
                                    marginBottom: "10%",
                                    display: 'inline-block',
                                }}
                            /> */}
                            <img src={generateImageUrl(architecture.blobUrl)} style={{ maxWidth: "1441px", maxHeight: "950px" }} />

                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const ChallengesAndRecommendations = () => {
        const challengesAndRecommendations = reportData?.challengesRecommendations ? reportData.challengesRecommendations.map((data: any) => ({
            title: data.find((attr: any) => attr.attributeName === "Title")?.attributeValue,
            recommendations: data.find((attr: any) => attr.attributeName === "Recommendations")?.attributeValue,
            challenges: data.find((attr: any) => attr.attributeName === "Challenges")?.attributeValue,
        }))?.filter((item: any) => item.title && (item.recommendations || item.challenges)) : [];

        return (
            <>
                {/*Business Overview Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Challenges & Recommendation</h5>
                </div>
                {/*Business Overview Header  ends here */}
                <div style={{ width: '98%', float: 'left', margin: '1% 0%', pageBreakInside: "avoid" }}>
                    {/* Main header style */}
                    {challengesAndRecommendations.map((data: any, index: any) => (
                        <>
                            <h2 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                                {data.title}</h2>
                            <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                <strong>Challenges:</strong> <div dangerouslySetInnerHTML={{ __html: data.recommendations }} />

                            </p>
                            <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                <strong>Recommendation:</strong> <div dangerouslySetInnerHTML={{ __html: data.challenges }} />
                            </p>
                        </>
                    ))}
                </div>
            </>
        )
    }

    const CurrentAwsCost = () => {

        const awsCosts = reportData.currentCost?.AWS?.current || [];

        const totalMonthlyCost = awsCosts.reduce((sum: any, item: any) => sum + parseFloat(item.monthlyCost.replace('$', '')), 0);
        const totalYearlyCost = awsCosts.reduce((sum: any, item: any) => sum + parseFloat(item.yearlyCost.replace('$', '')), 0);

        return (
            <>
                {/*Business Overview Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Current AWS Cost</h5>
                </div>
                {/*Business Overview Header  ends here */}
                <div style={{ width: '96%', float: 'left', padding: '2%' }}>
                    {/* <h5 style="color: #000; font-family: Arial ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '3%' }}>
                        <thead style={{ backgroundColor: '#F0F0F0' }}>
                            <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Service/Software</th>
                                <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Monthly Cost</th>
                                <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Yearly Cost</th>
                                <th style={{ width: '30%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awsCosts.map((item: any, index: any) => (
                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                    <td style={{ width: '18%', padding: '1%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        {item.softwareName}
                                    </td>
                                    <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        $ {item.monthlyCost}
                                    </td>
                                    <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                        $ {item.yearlyCost}
                                    </td>
                                    <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                        {item.comments}
                                    </td>
                                </tr>
                            ))}
                            <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <td style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5', fontWeight: 700 }}>
                                    Total
                                </td>
                                <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5', fontWeight: 600 }}>
                                    $ {totalMonthlyCost.toFixed(2)}
                                </td>
                                <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 600, color: '#242424', lineHeight: '1.5' }}>
                                    $ {totalYearlyCost.toFixed(2)}
                                </td>
                                <td style={{ width: '30%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    const CurrentInventoryCost = () => {


        const inventoryCosts = reportData.currentCost?.INVENTORY || [];

        const totalMonthlyCost = inventoryCosts.reduce((sum: any, item: any) => sum + parseFloat(item.monthlyCost), 0);
        const totalYearlyCost = inventoryCosts.reduce((sum: any, item: any) => sum + parseFloat(item.yearlyCost), 0);

        return (
            <>
                {/*Business Overview Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Current Inventory Cost</h5>
                </div>
                {/*Business Overview Header  ends here */}
                <div style={{ width: '96%', float: 'left', padding: '2%' }}>
                    {/* <h5 style="color: #000; font-family: Arial ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '2%' }}>
                        <thead style={{ backgroundColor: '#F0F0F0' }}>
                            <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Service/Software</th>
                                <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Monthly Cost</th>
                                <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Yearly Cost</th>
                                <th style={{ width: '30%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                    Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryCosts.map((item: any, index: any) => (
                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                    <td style={{ width: '18%', padding: '1%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        {item.softwareName}
                                    </td>
                                    <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        $ {item.monthlyCost}
                                    </td>
                                    <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                        $ {item.yearlyCost}
                                    </td>
                                    <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                        {item.comments}
                                    </td>
                                </tr>
                            ))}
                            <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <td style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5', fontWeight: 700 }}>
                                    Total
                                </td>
                                <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5', fontWeight: 600 }}>
                                    $ {totalMonthlyCost.toFixed(2)}
                                </td>
                                <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 600, color: '#242424', lineHeight: '1.5' }}>
                                    $ {totalYearlyCost.toFixed(2)}
                                </td>
                                <td style={{ width: '30%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    const FutureAwsCost = () => {
        const futureCosts = reportData?.futureCost?.AWS || {};
        const parseCost = (cost: any) => {
            const costWithoutFormat = cost.replace(/[\$,]/g, '');
            return parseFloat(costWithoutFormat) || 0;
        }


        const renderTable = (approach: any, data: any) => {

            const totalMonthlyCost = data.reduce((sum: any, item: any) =>
                sum + parseCost(item.monthlyCost || '0'), 0);
            const totalYearlyCost = data.reduce((sum: any, item: any) =>
                sum + parseCost(item.yearlyCost || '0'), 0);

            return (
                <>

                    <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                        <h2 style={{ fontSize: '13px', fontWeight: 700, fontFamily: 'Arial', marginTop: '20px', marginBottom: '10px' }}>
                            {approach}
                        </h2>
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '2%' }}>
                            <thead style={{ backgroundColor: '#F0F0F0' }}>
                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                    <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                        Service/Software</th>
                                    <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                        Monthly Cost</th>
                                    <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                        Yearly Cost</th>
                                    <th style={{ width: '30%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%', border: '1px solid #E7E7E7' }}>
                                        Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item: any, index: any) => (
                                    <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                        <td style={{ width: '18%', padding: '1%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                            {item.softwareName}
                                        </td>
                                        <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                            $ {item.monthlyCost}
                                        </td>
                                        <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '10px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                            $ {item.yearlyCost}
                                        </td>
                                        <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                            {item.comments}
                                        </td>
                                    </tr>
                                ))}
                                <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                    <td style={{ width: '18%', padding: '1%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        Total
                                    </td>
                                    <td style={{ width: '15%', padding: '1%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                        $ {totalMonthlyCost.toFixed(2)}
                                    </td>
                                    <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'right', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 400, color: '#242424', lineHeight: '1.5' }}>
                                        $ {totalYearlyCost.toFixed(2)}
                                    </td>
                                    <td style={{ width: '30%', padding: '1%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            );
        };
        return (
            <>
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5', pageBreakAfter: 'avoid', }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                        Future State AWS Cost</h5>
                </div>
                {Object.keys(futureCosts).map((approachKey) => {
                    const approachData = Array.isArray(futureCosts[approachKey]) ? futureCosts[approachKey] : [];
                    return renderTable(approachKey, approachData);
                })}
            </>
        );
    };

    const Roadmap = () => {
        return (
            <>
                {/*AWS Well-Architected Framework - Findings Header starts here */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '12px 8px', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Roadmap</h5>
                </div>
                {/*AWS Well-Architected Framework - Findings Header  ends here */}
                <div style={{ width: '96%', float: 'left', padding: '16px' }}>
                    {/* <h5 style="color: #000; font-family: Arial ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '16px' }}>
                        <thead style={{ backgroundColor: '#F0F0F0' }}>
                            {<tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%' }}>Phase
                                </th>
                                <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '13px', fontFamily: '"Roboto"', padding: '1%' }}>Process
                                </th>
                                {weeks?.map((week, index, processWeek) => (
                                    <th key={index} style={{ width: '13%', textAlign: 'left', color: '#454545', fontSize: '11px', fontFamily: '"Roboto"', padding: '1%', whiteSpace: 'nowrap', borderRight: processWeek.length - 1 === index ? '1px solid #E7E7E7' : '' }}>{week}</th>
                                ))}
                            </tr>}
                        </thead>
                        <tbody>
                            {migrationPlanData && migrationPlanData?.map((phaseData: any, phaseIndex: number) => (
                                phaseData?.processTimeLine?.map((process: any, processIndex: number) => (
                                    <tr key={`${phaseIndex}-${processIndex}`} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                        {processIndex === 0 && (
                                            <td rowSpan={phaseData.processTimeLine.length} style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                {phaseData.phaseName}
                                            </td>
                                        )}

                                        <td style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                            {process.processName}
                                        </td>

                                        {/* Assuming handleBindingWeekRange is a function that returns a valid JSX component or HTML element */}
                                        {handleBindingWeekRange(process, processIndex)}
                                    </tr>
                                ))
                            ))
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    const Conclusion = () => {
        const conclusion = reportData?.conclusion ? reportData?.conclusion?.map((data: any) => ({
            description: data?.find((attr: any) => attr.attributeName.includes("Conclusion Description"))?.attributeValue,
        })) : [];

        const removeHtmlTags = (text: any) => {
            const regex = /<[^>]*>|"/g;
            return text?.replace(regex, '');
        };

        return (
            <>
                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Conclusion</h5>
                </div>
                <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '12px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                        <div dangerouslySetInnerHTML={{ __html: conclusion[0]?.description }} />
                    </p>
                </div>
            </>
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ width: '100%', float: 'left', margin: '2% 0 85% 0%', pageBreakInside: 'avoid' }}>
                                {/*Reports Header starts here */}
                                <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '15px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                        Reports</h5>
                                </div>
                                {/*Reports Header  ends here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
                                    {/* Main header style */}
                                    <h4 style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 700, fontSize: '14px', margin: '3% 0% 2% 0%' }}>
                                        Table of content</h4>
                                    {/*page header style */}
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Executive Summary
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            3
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Business Overview
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            5
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Business Flow
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            6
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Workflow Component
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            8
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Future State Architecture
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            9
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Challenges & Recommendation
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            10
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Cuurent AWS Cost
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            11
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Cuurent Inventory Cost
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            12
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Future State AWS Cost
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            13
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Roadmap
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            14
                                        </label>
                                    </div>
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                            Conclusion
                                        </label>
                                        <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 6%', float: 'right' }}>
                                            15
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {ExecutiveSummary()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}  >
                                {BusinessOverview()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {BusinessFlow()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {WorkFlow()}
                            </div>
                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {(CurrentStateArchitecture())}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {FutureStateArchitecture()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {ChallengesAndRecommendations()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {CurrentAwsCost()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {CurrentInventoryCost()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {FutureAwsCost()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '8px 0' }}>
                                {Roadmap()}
                            </div>

                            <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }}>
                                {Conclusion()}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AwsMdpwDowloadReport;
import { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { BarChart, Bar, XAxis, YAxis, LabelList, Text } from "recharts";

const renderPieChart = (
  pieChartMockData: any,
  vmwareStats: any,
  renderCustomizedLegend: any
) => {
  const totalValue = pieChartMockData.reduce(
    (sum: number, item: any) => sum + item.value,
    0
  );

  const dataToRender =
    totalValue === 0
      ? [{ label: "No Data", value: 1, color: "#E0E0E0" }]
      : pieChartMockData;

  return (
    <div style={{ width: 300, height: 350, marginLeft: "20px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={dataToRender}
            cx="50%"
            cy="50%"
            innerRadius={90}
            outerRadius={110}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
          >
            {dataToRender.map((entry: any, index: any) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label
              content={({ viewBox: { cx, cy } }: any) => (
                <g>
                  <text
                    x={cx}
                    y={cy - 30}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fontSize: "14px",
                      fill: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Total VM Count
                  </text>
                  <text
                    x={cx}
                    y={cy}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{ fontSize: "24px", fontWeight: "bold" }}
                  >
                    {vmwareStats.totalVmCount || 0}
                  </text>
                </g>
              )}
              position="center"
            />
          </Pie>
          <Legend
            content={renderCustomizedLegend}
            verticalAlign="bottom"
            height={36}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const calculateYAxisMax = (maxValue: number) => {
  if (maxValue === 0) return 100;

  // Round up to the nearest 20 if we give 10 means the numbers are not algining properly. 
  const roundedUp = Math.ceil(maxValue / 10) * 20;

  // Add an extra 10% for padding
  return Math.ceil(roundedUp);
};

const renderVerticalBarChart = (data: any, CustomXAxisTick: any) => {
  const safeData = Array.isArray(data) ? data : [];

  const chartData = safeData.length > 0 ? safeData : [{ name: "", value: 0 }];

  const maxValue = Math.max(...chartData.map((item: any) => item.value || 0));
  const yAxisMaxValue = calculateYAxisMax(maxValue);
  // const yAxisMaxValue = maxValue === 0 ? 100 : Math.ceil(maxValue / 20) * 20;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          tick={<CustomXAxisTick />}
          height={100}
          interval={0}
          axisLine={true}
          tickLine={false}
          tickFormatter={(value) => value}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <YAxis
          axisLine={true}
          tickLine={false}
          domain={[0, yAxisMaxValue]}
          tickFormatter={(value) => value.toString()}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
          scale="linear"
          // allowDataOverflow={true}
          ticks={[0, Math.round(yAxisMaxValue / 4), Math.round(yAxisMaxValue / 2), Math.round(yAxisMaxValue * 3 / 4), yAxisMaxValue]}
        />
        <Bar dataKey="value" fill="#acbcfc" barSize={40} minPointSize={2}>
          <LabelList dataKey="value" position="top" fill="#000" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const renderHorizontalBarChart = (data: any, CustomYAxisTick: any) => {
  const safeData = Array.isArray(data) ? data : [];

  const chartData =
    safeData.length > 0
      ? safeData
      : [
          { name: "No Data", value: 0 },
          { name: " ", value: 0 },
        ];

  const maxValue = Math.max(...chartData.map((item: any) => item.value || 0));
  // const xAxisMaxValue = maxValue === 0 ? 100 : Math.ceil(maxValue / 20) * 20;
  const xAxisMaxValue = calculateYAxisMax(maxValue);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{
          top: -15,
          right: 50,
          left: 65,
          bottom: 5,
        }}
      >
        <XAxis
          type="number"
          // domain={[0, xAxisMaxValue]}
          // ticks={[0, 20, 40, 60, 80, 100].filter(
          //   (tick) => tick <= xAxisMaxValue
          // )}
          axisLine={true}
          tickLine={false}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
          tickFormatter={(value) => `${value}`}
          domain={[0, xAxisMaxValue]}
          ticks={[0, Math.round(xAxisMaxValue / 4), Math.round(xAxisMaxValue / 2), Math.round(xAxisMaxValue * 3 / 4), xAxisMaxValue]}
        />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={true}
          tickLine={false}
          tick={<CustomYAxisTick />}
          width={5}
          tickMargin={10}
          interval={0}
          
        />
        <Bar dataKey="value" fill="#acbcfc" barSize={40} minPointSize={2}>
          <LabelList dataKey="value" position="right" fill="#000" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const renderCustomizedLegend = (props: any) => {
  const { payload } = props;
  return (

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "80px",
      }}
    >
      {payload.map((entry: any, index: any) => (
        <div
          key={`item-${index}`}
          style={{ 
            display: "flex", 
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: entry.color,
              marginRight: "8px",
              borderRadius: "50%",
              flexShrink: 0, // Prevent the color dot from shrinking
            }}
          ></span>
          <span 
            style={{ 
              fontSize: "14px",
              whiteSpace: "nowrap", // Prevent text wrapping
              overflow: "hidden", // Hide overflowing text
              textOverflow: "ellipsis", // Show ellipsis for overflowing text
              maxWidth: "calc(100% - 20px)", // Adjust based on the width of the color dot and spacing
            }}
          >
            {entry.payload.label}
          </span>
          <span
            style={{ 
              marginLeft: "8px", 
              fontWeight: "bold", 
              fontSize: "14px",
              flexShrink: 0, // Prevent the value from shrinking
            }}
          >
            {entry.payload.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomXAxisTick = ({ x, y, payload }: any) => {
  const words = payload.value.split(" ");
  const maxWordsPerLine = 2;
  const lines = [];

  for (let i = 0; i < words.length; i += maxWordsPerLine) {
    lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
  }

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <Text
          key={index}
          x={0}
          y={index * 20}
          dy={16}
          textAnchor="middle"
          fill="#000"
          fontSize={12}
          fontWeight="bold"
        >
          {line}
        </Text>
      ))}
    </g>
  );
};

const CustomYAxisTick = ({ x, y, payload }: any) => {
  const words = payload.value.split(" ");
  const maxWordsPerLine = 2;
  const lines = [];

  for (let i = 0; i < words.length; i += maxWordsPerLine) {
    lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
  }

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <Text
          key={index}
          x={-10}
          y={index * 50}
          dy={4}
          textAnchor="end"
          fill="#000"
          fontSize={12}
          fontWeight="bold"
        >
          {line}
        </Text>
      ))}
    </g>
  );
};

const DiscoveryGraphComponent = ({ vmwareStats }: any) => {
  const [selectedCluster, setSelectedCluster] = useState(
    vmwareStats?.totalClusters[0]?.clusterName || ""
  );
  const selectedClusterData = vmwareStats.totalClusters.find(
    (cluster: any) => cluster.clusterName === selectedCluster
  );

  const pieChartMockData = [
    { label: "Power On", value: vmwareStats.totalPowerOn, color: "#9FF5A2" },
    { label: "Power Off", value: vmwareStats.totalPowerOff, color: "#FFA79D" },
  ];

  const barGraphMockData = vmwareStats.totalVmOscount.map((item: any) => ({
    name: item.osName,
    value: item.osByVm,
  }));

  const totalNodes = vmwareStats.totalClusters.reduce(
    (acc: any, cluster: any) => acc + cluster.totalNoOfNodes,
    0
  );

  const totalClustersAndNodes = [
    { name: "Cluster", value: vmwareStats.totalNoOfCluster },
    { name: "Node", value: totalNodes },
  ];

  const barGraphData = selectedClusterData?.vmHosts?.map((item: any) => ({
    name: item.hostname,
    value: item.vmcount,
  }));

  return (
    <div
      className="tab-pane fade show active"
      id="Inventorytab"
      role="tabpanel"
    >
      <h5 className="font-18 font-semibold color-black mb-5">
        Discovery - Graph
      </h5>
      <div className="col-md-12">
        <div className="row">
          {/* Horizontal Bar Chart and Pie Chart side by side */}
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div
              className="p-4 h-100 border rounded-3"
              // style={{ minHeight: "400px" }}
            >
              <p className="font-semibold font-18 color-black mb-4">
                Total Count of Cluster & Node
              </p>
              <div
                className="w-100 d-flex justify-content-center align-items-center totalClustersAndNodesElement"
                // style={{ height: "300px" }}
              >
                {renderHorizontalBarChart(
                  totalClustersAndNodes,
                  CustomYAxisTick
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div
              className="p-4 h-100 border rounded-3"
              // style={{ minHeight: "400px" }}
            >
              <h3 className="font-18 font-semibold">VM Status</h3>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                // style={{ height: "300px" }}
              >
                <div className="d-flex justify-content-center mt-4">
                  {renderPieChart(
                    pieChartMockData,
                    vmwareStats,
                    renderCustomizedLegend
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Dropdown and Vertical Bar Chart */}
          <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-semibold font-18 color-black mb-0">
                  Total no of Nodes for Cluster - {selectedCluster}
                </p>
                <select
                  name="selectDomain"
                  id="selectDomain"
                  className="form-select form-control custom-form width-200 rounded-2"
                  style={{width:"250px"}}
                  value={selectedCluster}
                  onChange={(e) => setSelectedCluster(e.target.value)}
                  // className="font-15 color-black-v2 font-medium"
                  // style={{ padding: "5px", borderRadius: "4px" }}
                >
                  {vmwareStats.totalClusters.map((cluster: any) => (
                    <option
                      key={cluster.clusterName}
                      value={cluster.clusterName}
                    >
                      {cluster.clusterName}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="w-100 d-flex justify-content-center align-items-center mt-4 w-md-100"
                style={{ height: "400px" }}
              >
                {" "}
                {/* Increased height */}
                {renderVerticalBarChart(barGraphData, CustomXAxisTick)}
              </div>
            </div>
          </div>

          {/* Second Vertical Bar Chart */}
          <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">
                VM Count by OS
              </p>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "400px" }}
              >
                {" "}
                {/* Increased height */}
                {renderVerticalBarChart(barGraphMockData, CustomXAxisTick)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoveryGraphComponent;

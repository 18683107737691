//PS_MapReport_01 - Import required modules and interfaces from respective paths
import React, { useEffect, useRef, useState } from "react"
import { strategySortOrderInferface, planSortOrderInferface, migrationStrategyDataInterface, migrationPlanDataInterface, phaseData, weekDataState, mapFileInfoModel, uploadTranscriptMapModel, StrategyListItem, uploadTranscriptSummaryModel, azureInventoryModel, AzureService } from "../interface/infraMapModel"
import MapHorizondialBarChart from "./reportComponents/MapHorizondialBarChart"
import MapLineChart from "./reportComponents/MapLineChart"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import PopupComponent from "./reportComponents/MapApplicationStrategyGridPopup"
import BreadCrumbs from "./breadCrumb"
import { getSasToken, PostRecommendedArchitecture, postSummaryData, postUploadedTranscriptData } from "../service/ReportApi"
import { postUploadedSummaryTranscriptData } from "../service/ReportApi"
import { encryptStorage } from "../constant/constant"
import { jwtDecode } from "jwt-decode"
import { getConnectorsData } from "../helpers/encryption"
import UploadTranscript from "./reportComponents/UploadTranscript"
import { postSummaryModel, uploadTranscriptModel, wafrFileInfoModel } from "../interface/WafrReportModel"
import Loader from "./Loader"
import ExecutiveSummary from "./reportComponents/ExecutiveSummary"
import { UpdateMigrationStrategy, getMigrationStrategyDropdown, postInfraApplicationDetails } from "../service/InfraAssessmentApi"
import MigrationPlan from "./MigrationPlan"
import CostBreakdown from "./costBreakdown"
import uploadFileToBlob1 from "../helpers/BlobUpload"
import TextEditor from "./reportComponents/TextEditor"
import { report } from "process"


const InfrastructureMapReport = ({ initialReportData }: any) => {
  //  PC_MapInfraReportComp_02, PC_MapInfraReportComp_03, PC_MapInfraReportComp_04PC_MapInfraReportComp_05, PC_MapInfraReportComp_06, PC_MapInfraReportComp_07, PC_MapInfraReportComp_08,
  //   PC_MapInfraReportComp_09, PC_MapInfraReportComp_10, PC_MapInfraReportComp_11, PC_MapInfraReportComp_12, PC_MapInfraReportComp_13, PC_MapInfraReportComp_14 - Initialize all the state variables

  const initialFileInfo: wafrFileInfoModel = {
    fileData: "",
    fileName: "",
    fileType: "",
    base64Transcript: ""
  }

  const [initialSummaryData, setInitialSummaryData] = useState({
    directive:"",
    outcome:"",
    executiveSummary: "",
    businessOverview: "",
    finalReportWalkthrough: ""
  })

  const closeModal = () => {
    setShowDeleteModal(false);
  }

  const regex = /"|[*]/g;

  //PS_MapReport_02 - Initialize state variables with default values: executiveSummary, businessOverview, finalReportWalkthrough, reportData, selectedNavBar, summaryFile, isLoading, isSaving, saveSuccess, showDeleteModal, etc.
  const [reportData, setReportData] = useState<any>(initialReportData)
  const [summaryData, setSummaryData] = useState<any>(initialSummaryData)
  const [selectedNavBar, setSelectedNavBar] = useState<string>("uploadTranscript")
  const [summaryFile, setSummaryFile] = useState<wafrFileInfoModel>(initialFileInfo)
  const [uploadedFile, setUploadedFile]= useState<wafrFileInfoModel>(initialFileInfo)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [migrationStrategyData, setMigrationStrategyData] = useState<any>([...initialReportData.migrationStrategy.applicationsStrategy])
  const [migrationPlanData, setMigrationPlanData] = useState<any>(JSON.parse(JSON.stringify(initialReportData.migrationPlan)))
  const [applicationDetails, setApplicationDetails] = useState<any>(initialReportData.discovery[0])
  const [strategySortOrder, setStrategySortOrder] = useState<strategySortOrderInferface>({ name: false, summary: false })
  const [planSortOrder, setPlanSortOrder] = useState<planSortOrderInferface>({ phase: false, process: false })
  const [weeks, setWeeks] = useState<string[]>([]);
  const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<weekDataState>({ minDate: new Date(), weekNumber: 1 });
  const [tableExpand, setTableExpand] = useState<boolean>(false);
  const [strategyList, setStrategyList] = useState<any>([...initialReportData.strategyDetails]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedValues, setEditedValues] = useState<any>({});
  const navigate = useNavigate()
  const fileInputRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showToasterLoading, setShowToasterLoading] = useState(false);
  const [showToasterPopup, setShowToasterPopup] = useState(false);
  const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isAzure, setIsAzure] = useState(initialReportData.isAzure);
  const [hideToast, setHideToast] = useState(true);
  const [allApplicationDetails, setAllApplicationDetails] = useState<{ [key: string]: any }>({});
  const [toastProp, setToastProp] = useState({
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  });
  const [finalWalkthroughFile, setFinalWalkthroughFile] = useState<wafrFileInfoModel>(initialFileInfo)

  const [costBreakdownDetails, setCostBreakdownDetails] = useState(
    initialReportData?.costBreakdownDetails
  );

  const environmentType = initialReportData.isAzure ? "azure" : "on-premises"
  const breadCrumbs =
    [
      { title: "Organization", path: "/homepage", isActive: true },
      {
        title: initialReportData?.organizationName,
        path: "/manageassessment",
        isActive: true,
        state: {
          organizationId: initialReportData?.organizationId,
          organizationName: initialReportData?.organizationName,
        },
      },
      { title: initialReportData.verticalName, path: "", isActive: true },
      { title: "Report", path: "", isActive: false },
    ]

  // PS_MapReport_04 - Upon component load or migrationPlanData change: - Call updateWeeks function and update minDateAndRoundValue with the result
  useEffect(() => {
    const weekData = updateWeeks();
    setMinDateAndRoundValue(weekData);
  }, [migrationPlanData]);

  //PS_MapReport_05 - Upon component load: - Attach handleBeforeUnload function to warn users before an unload event (like page refresh or close)
  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault(); // Prevent the page from reloading
      e.returnValue =
        "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
    };

    window.onbeforeunload = function () {
      return "You may have unsaved changes. Are you sure you want to reload or leave?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);

  //PS_MapReport_06 - Upon initialReportData change: - Update initialSummaryData based on infraSummary attributes
  useEffect(() => {
    initialReportData?.infraSummary?.forEach((attributes: any) => {
      switch (attributes.attributeName) {
        case "Executive Summary":
          setInitialSummaryData((prev) => ({
            ...prev,
            executiveSummary: attributes?.attributeValue,
          }));
          break;

        case "Business Overview":
          setInitialSummaryData((prev) => ({
            ...prev,
            businessOverview: attributes?.attributeValue,
          }));
          break;

        case "Directive":
          setInitialSummaryData((prev)=> ({
            ...prev,
            directive: attributes?.attributeValue,
          }));
          break;
        case "Outcome":
          setInitialSummaryData((prev)=> ({
            ...prev,
            outcome: attributes?.attributeValue,
          }));
          break;
        case "Final Walkthrough":
          setInitialSummaryData((prev) => ({
            ...prev,
            finalReportWalkthrough: attributes?.attributeValue,
          }));
          break;
      }
    });
  }, [initialReportData]);

  function escapeJsonString(str: string): string {
    return str
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"')
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/\t/g, "\\t")
      .replace(/\f/g, "\\f");
  }

  //PS_MapReport_08 Define async function to post uploaded transcript data and handle API response or error
  const postUploadedTranscript = async (key: string) => {
    let payload: uploadTranscriptModel = {
      organizationId: reportData?.organizationId,
      projectId: reportData?.projectId,
      verticalId: reportData?.verticalId,
      verticalName: reportData?.verticalName,
      isSummary: key === "executiveSummary",
      recommendations: [],
      url: [],
      fileName: key === "executiveSummary"? summaryFile.fileName: finalWalkthroughFile.fileName,
      fileType: '.docx',
      fileData: key === "executiveSummary"? summaryFile.base64Transcript: finalWalkthroughFile.base64Transcript ,
    }
    setShowToasterLoading(true);
    try {
      const response = await postUploadedTranscriptData(payload)

      if (response.status === 200) {
        setShowToasterPopup(true);
        //setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        if(key === "executiveSummary")
          setInitialSummaryData({
            ...initialSummaryData,
            executiveSummary: response?.data?.executiveSummary,
            businessOverview: response?.data?.businessOverview
          });
        else
          setInitialSummaryData({
            ...initialSummaryData,
            finalReportWalkthrough: response?.data?.finalWalkthrough
          });
      } else {
        // setIsLoading(false);
        let toastData = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        };
        setToastProp(toastData)
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
      //setIsLoading(false)
    } finally {
      //setIsLoading(false);
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }
  }

  // PS_MapReport_09 - Define async function to post summary data and handle API response or error
  const postSummary = async (key: string, value: string) => {
    setShowToasterLoading(true);
    let payload: postSummaryModel = {
      organizationId: reportData.organizationId,
      verticalId: reportData.verticalId,
      projectId: reportData.projectId,
      attributeId: "",
      attributeValue: escapeJsonString(value?.trim().replace(regex, "")),
      attributeName: key === "executiveSummary" ? "Executive Summary" : key === "directive" ? "Directive" : key === "outcome" ? "Outcome" : key === "businessOverview" ? "Business Overview" : "Final Walkthrough"
    };
    try {
      const response = await postSummaryData(payload)
      if (response.status === 200) {
        setShowToasterPopup(true);
        //setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (key === "executiveSummary")
          setInitialSummaryData((prev) => ({
            ...prev,
            executiveSummary: value?.trim(),
          }));
        else if (key === "directive")
          setInitialSummaryData((prev) => ({
            ...prev,
            directive: value?.trim(),
          }));
        else if (key === "outcome")
          setInitialSummaryData((prev) => ({
            ...prev,
            outcome: value?.trim(),
          }));
        else if (key === "businessOverview")
          setInitialSummaryData((prev) => ({
            ...prev,
            businessOverview: value?.trim(),
          }));
        else
          setInitialSummaryData((prev) => ({
            ...prev,
            finalReportWalkthrough: value?.trim(),
          }));
      } else {
        ////setIsLoading(false);
        let toastData = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        }
        setToastProp(toastData)
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
      //setIsLoading(false);
    } finally {
      //setIsLoading(false)
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }
  }

  //Define async function to generate SAS token for blob access
  const generateSasToken = async () => {
    try {
      let value = encryptStorage.getItem("jwt")
      let parsedJtk: any = ""
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value)
        const storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
        containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
      }
      const sas = await getSasToken("r");
      return (sas.data)

    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }

  }

  //Define async function to save file by fetching it with the SAS token and triggering the browser's download functionality
  const onSaveFile = async () => {
    const sas = await generateSasToken()
    const response = await fetch(reportData?.azureInventory.blobUrlAzureInventory + `?${sas}`);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${reportData?.organizationName}-inventory details.xlsx`;
    a.click();
  }




  //PC_MapInfraReportComp_59 - Function to change the application details
  const onClickApplicationDropDown = (event: any) => {

    const selectedApplicationName = event.target.value;
    const selectedApplication = reportData.discovery.find(
      (element: any) => element.applicationName === selectedApplicationName
    );

    if (selectedApplication) {
      const applicationId = selectedApplication.applicationId;
      const savedData = allApplicationDetails[applicationId] || {};

      setApplicationDetails({
        ...selectedApplication,
        ...savedData
      });
      if (savedData.applicationArchitUrl) {
        setAllApplicationDetails((prevDetails: any) => ({
          ...prevDetails,
          [applicationId]: {
            ...prevDetails[applicationId],
            applicationArchitUrl: savedData.applicationArchitUrl
          }
        }));
      }
    }
  };

  //PC_MapInfraReportComp_43 - Function to sort the migration strategy table
  const sortMigrationStrategy = (columnName: string) => {
    let sortedMigrationStrategy: migrationStrategyDataInterface[] = [];
    //PC_MapInfraReportComp_44 - Sort the application name
    if (columnName === 'name') {
      const applicationNames = migrationStrategyData?.map((element: any) => element.applicationName).sort();
      if (strategySortOrder.name) {
        applicationNames.reverse();
      }
      applicationNames.forEach((element: any) => {
        const foundItem = migrationStrategyData?.find((migrationStrategy: any) => {

          return migrationStrategy.applicationName === element
        });

        sortedMigrationStrategy.push(foundItem);

      });
      setStrategySortOrder({ ...strategySortOrder, name: !strategySortOrder.name });
    }
    //PC_MapInfraReportComp_45 - Sort the application summary
    else {
      let applicationSummaries: string[] = []
      migrationStrategyData.forEach((element: any) => {
        if (!applicationSummaries.includes(element.applicationStrategySummary))
          applicationSummaries.push(element.applicationStrategySummary)
      }
      )
      applicationSummaries.sort()
      if (strategySortOrder.summary) {
        applicationSummaries.reverse();
      }


      applicationSummaries.forEach((element: string) => {
        const foundItem = migrationStrategyData.filter((migrationStrategy: any, index: number) => migrationStrategy.applicationStrategySummary === element);
        if (foundItem) {
          sortedMigrationStrategy.push(...foundItem);
        }
      });
      setStrategySortOrder({ ...strategySortOrder, summary: !strategySortOrder.summary });
    }
    setMigrationStrategyData(sortedMigrationStrategy);
  }
  //PC_MapInfraReportComp_50 - Function to sort  migration plan processes
  const sortMigrationPlanProcess = (processDetails: any[], columnName: string): any[] => {
    let sortedProcessDetails = processDetails
      .map((element: any) => element.processName)
      .sort();

    if (planSortOrder.process) {
      sortedProcessDetails.reverse();
    }

    return sortedProcessDetails.map(processName => {
      return processDetails.find(processDetail => processDetail.processName === processName); // Assuming there will be a match
    });
  };
  //PC_MapInfraReportComp_48 - Function to sort migration plan
  const sortMigrationPlan = (columnName: string) => {
    let sortedMigrationPlan: migrationPlanDataInterface[];
    //PC_MapInfraReportComp_49 - Sort the migration plan phase
    if (columnName === 'phase') {
      const phaseNames = migrationPlanData
        .map((element: any) => element.phaseName)
        .sort();

      if (planSortOrder.phase) {
        phaseNames.reverse();
      }

      sortedMigrationPlan = phaseNames.map((phaseName: string) => {
        return migrationPlanData.find((migrationPlan: any) => migrationPlan.phaseName === phaseName)!; // Assuming there will be a match
      });
      setPlanSortOrder({ ...planSortOrder, phase: !planSortOrder.phase });

    }
    //PC_MapInfraReportComp_50 - Sort thr migration plan process
    else {
      sortedMigrationPlan = JSON.parse(JSON.stringify(migrationPlanData));
      sortedMigrationPlan.forEach((element, index) => {
        sortedMigrationPlan[index].processTimeLine = sortMigrationPlanProcess(element.processTimeLine, columnName);
      });
      setPlanSortOrder({ ...planSortOrder, process: !planSortOrder.process });
    }

    setMigrationPlanData(sortedMigrationPlan);
  };
  //PC_MapInfraReportComp_40 - Function to handle the sort event
  const onClickSort = (columnName: string) => {
    if (columnName === 'name' || columnName === 'summary') {
      sortMigrationStrategy(columnName);
    } else {
      sortMigrationPlan(columnName);
    }
  };

  interface ImageUploadModalProps {
    show: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
  }

  const ImageUploadModal: React.FC<ImageUploadModalProps> = ({ show, onClose, onUpload }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];
      // Implement your file validation logic here
      //setFile(selectedFile);
    };

    const handleUpload = () => {
      if (file) {
        onUpload(file);
        onClose();
      } else {
        setError('Please select a file to upload');
      }
    };

    return (
      <div className={`modal fade ${show ? 'show' : ''}`} id="_addSessionPopup" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-popup">
            <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
              <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                Upload Architecture
              </h2>
              <button type="button" className="btn" onClick={onClose}>
                <img src="images/popup-close.svg" alt="Close" />
              </button>
            </div>
            <div className="modal-body border-0 px-4">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label font-14 font-medium color-black" htmlFor="architectureUpload">
                    Upload Architecture
                  </label>
                  <div className="position-relative d-flex align-items-center">
                    <label
                      className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2"
                      htmlFor="architectureUpload"
                    >
                      <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                    </label>
                    <input
                      type="file"
                      className="form-control custom-file mb-4"
                      id="architectureUpload"
                      aria-label="upload"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    {/* {file && <span className="font-12 font-medium color-grey ms-5">{file.name}</span>} */}
                  </div>
                  {error && <div className="text-danger">{error}</div>}
                </div>
              </div>
            </div>
            <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
              <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={onClose}>Cancel</a>
              <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleUpload}>Upload</button>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const extractDatesFromProcesses = (data: phaseData[]) => {
    let datesArray: string[] = [];

    data?.forEach((phase) => {
      phase.processTimeLine.forEach((process) => {
        let startDate = moment(process.startWeek);
        let endDate = moment(process.endWeek);
        datesArray.push(startDate.format("YYYY-MM-DD"));
        datesArray.push(endDate.format("YYYY-MM-DD"));
      });
    });
    return datesArray;
  };

  const updateWeeks = () => {
    const allDates = extractDatesFromProcesses(migrationPlanData);

    const uniqueDates = Array.from(new Set(allDates));
    const dateObjects: Date[] = uniqueDates.map((date) => new Date(date));

    let minDate = new Date(dateObjects[0]);
    let maxDate = new Date(dateObjects[0]);

    dateObjects.forEach((date) => {
      if (date < minDate) minDate = new Date(date);
      if (date > maxDate) maxDate = new Date(date);
    });

    let weeks: string[] = [];
    let currentDate: Date = new Date(minDate);
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
    const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
    let roundedIncrement;

    if (weeksBetween % 4 != 0) {
      roundedIncrement = Math.ceil(weeksBetween / 4)
    } else {
      roundedIncrement = Math.round(weeksBetween / 4);
    }

    let weekNumber: number
    if (roundedIncrement == 0) {
      roundedIncrement = 1
      weekNumber = roundedIncrement
    } else {
      weekNumber = roundedIncrement
    }

    for (let i = 1; i <= 4; i++) {
      weeks.push(`week ${weekNumber}`);
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber += roundedIncrement;
    }

    setWeeks(weeks);
    return { "minDate": minDate, "weekNumber": roundedIncrement };
  };


  const differenceInDays = (dateA: Date, dateB: Date): number => {
    const timeDifference = dateA.getTime() - dateB.getTime();
    return Math.abs(Math.round(timeDifference / (1000 * 3600 * 24)));
  };

  const getWeekIndex = (date: Date, minDate: Date): number => {
    const diffDays = differenceInDays(date, minDate);
    const diffWeeks = Math.ceil(diffDays / 6);
    return diffWeeks > 0 ? diffWeeks : 1;
  };

  // PS_MigrationPlan_70 -  PS_MigrationPlan_76
  const getWeekRange = (startDate: Date, endDate: Date, process: any, minDate: Date, weekNumber: number): any => {

    const startWeekIndex = getWeekIndex(startDate, minDate);
    const endWeekIndex = getWeekIndex(endDate, minDate);

    const { marginLeft, marginRight } = calculateMargins(startWeekIndex, endWeekIndex, weekNumber);
    if (startWeekIndex !== -1 && endWeekIndex !== -1) {
      if (startWeekIndex === endWeekIndex) {
        return { "weekRange": `Week ${startWeekIndex}`, "marginRight": marginRight, "marginLeft": marginLeft };
      } else {
        return { "weekRange": `Week ${startWeekIndex} - Week ${endWeekIndex}`, "marginRight": marginRight, "marginLeft": marginLeft };
      }
    } else {
      return {};
    }
  };

  function calculateMargins(startIndex: any, endIndex: any, weekNumber: number) {

    startIndex = Math.round(startIndex < weekNumber ? startIndex : startIndex / weekNumber)
    endIndex = Math.round(endIndex < weekNumber ? endIndex : endIndex / weekNumber)
    const totalParts = 4;
    const marginLeft = (startIndex - 1) * (100 / totalParts);
    const marginRight = (totalParts - endIndex) * (100 / totalParts);

    return {
      marginLeft: `${marginLeft}%`,
      marginRight: `${marginRight}%`
    };

  }


  const handleBindingWeekRange = (process: any, index: any) => {

    const startDate = new Date(process.startWeek);
    const endDate = new Date(process.endWeek);

    const weekData = getWeekRange(startDate, endDate, process, minDateAndRoundValue.minDate, minDateAndRoundValue.weekNumber);

    return (
      <td key={`week-range-${index}`} colSpan={4} className="my-2 px-0 py-2 position-relative" >
        <span className="d-block text-nowrap text-start font-13 cal-blue-label cal-label-sty" style={{ marginLeft: weekData.marginLeft, marginRight: weekData.marginRight }} >
          {weekData.weekRange}
        </span>
      </td>
    );
  }

  const formateDate = (updatedDate: string) => {
    const updatedDate1 = moment(updatedDate.split("T").at(0))
    return (updatedDate1.format("DD-MM-YYYY"))
  }

  //PC_MapInfraReportComp_60 - Function to bind the map menus
  const bindMapMenus = () => {
    return (reportData.menus.map((element: any) => {
      return (<button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedNavBar === element.menuName ? "active" : ""}`} data-bs-toggle="pill" id={element.menuName} type="button" role="tab" aria-selected="true" onClick={(event: any) => { setSelectedNavBar(event.target.id) }}>{element.menuName === "Cloud Adoption Framework" ? "CAF" : element.menuName}</button>)
    }))
  }

  //PC_MapInfraReportComp_61 - Funciton to bind the application names
  const bindApplicationDropdown = () => {
    return reportData?.discovery.map((element: any) =>
      <option key={element.applicationId} value={element.applicationName}  >
        {element.applicationName}
      </option>)
  }
  //Define function to render inventory details for services based on reportData.inventoryDetails
  const bindInventoryDetails = () => {
    return (
      reportData.azureInventory?.serviceCount?.map((data: AzureService) => {
        return (
          <div className="col-md-4 pb-3 pe-3 ps-0">
            <div className="aws-card d-flex justify-content-between align-items-center">
              <span className="d-flex align-items-center gap-3">
                {/* <img src={`images/aws-${data.serviceName?.replaceAll(" ", "")?.toLowerCase()}.svg`} alt="aws-ebs-icon" /> */}
                <img src="images/default_org_logo.svg" />
                <span className="font-14 font-semibold">{data.serviceName}</span>
              </span>
              <span className="font-24 font-semibold">{data.serviceCount}</span>
            </div>
          </div>
        )
      })
    )
  }

  //Define function to render Azure Inventory as a component with its own state and inventory details
  const AzureInventory = () => {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="ps-lg-4">
            <h3 className="font-18 font-semibold color-black mb-5">
              Inventory
            </h3>
            <div className="row">
              {bindInventoryDetails()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  //Define function to render Inventory as a component, either Azure or on-premises based on isAzure state
  const Inventory = () => {
    if (isAzure) {
      return <AzureInventory />;
    }
    return (<div className="tab-pane fade show active" id="Inventorytab" role="tabpanel"  >
      <h5 className="font-18 font-semibold color-black mb-5">Inventory</h5>
      <div className="col-md-12 ">
        <div className="row">
          <div className="col-xl-5 col-lg-12 col-md-6 col-sm-12">
            <div className="p-4 h-100">
              {/* <p class="font-semibold font-18 font-semibold mb-4">SQL Servers - Enterprise, Standard, Web</p> */}
              <div className="row g-5">
                <div className="col-sm-12 col-md-12 col-lg-10 ">
                  {/* <h4 className="font-18 font-semibold color-black my-3">INVENTORY</h4> */}
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium ">Total instances count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.instancesCount ?? 0}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Windows server count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.windowsServerCount ?? 0}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Linux server count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.linuxServerCount ?? 0}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Windows Desktops count</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.windowsDesktopsCount ?? 0}</label>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span className="d-flex align-items-center">
                      <label className="font-15 color-black-v2 font-medium">Server not patched</label>
                    </span>
                    <label className="font-32 font-semibold color-black">{reportData.inventory.serverNotPatched ?? 0}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-7 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">Percent Memory Used</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxMemoryUsed} xAxisLabel="Utilization Percentage" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">CPU Load</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxCpuLoad} xAxisLabel="Utilization Percentage" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-4">
            <div className="p-4 h-100 border rounded-3">
              <p className="font-semibold font-18 color-black mb-4">Disk Size</p>
              <div className=" w-100 d-flex justify-content-center align-items-center">
                {/* Vertical bar chart starts here */}
                <MapHorizondialBarChart barChartData={reportData.inventory.maxDiskSize} xAxisLabel="Utilization Percentage" />
                {/* Vertical bar chart ends here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }

  const resetFileInput = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).value = '';
    }
  };
  const validateImage = (file: any, minWidth = 1000, minHeight = 1500, minDPI = 300) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve({ isValid: false, errorMessage: 'No file selected.' });
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {

          const dpiX = (img.width / (file.width / 25.4));
          const dpiY = (img.height / (file.height / 25.4));

          if (img.width < minWidth || img.height < minHeight || dpiX < minDPI || dpiY < minDPI) {
            reject(`Image resolution is too low`);
          } else {
            resolve({ isValid: true, errorMessage: '' });
          }
        };
        img.onerror = () => {
          reject('Error loading image. Please try again.');
        };
        img.src = (event?.target?.result as string);
      };

      reader.onerror = () => {
        reject('Error reading file. Please try again.');
      };

      reader.readAsDataURL(file);
    });
  };

  const postSummaryTranscript = async (key: string) => {
    let payload: uploadTranscriptSummaryModel = {
      organizationId: reportData?.organizationId,
      projectId: reportData?.projectId,
      verticalId: reportData?.verticalId,
      applicationId: applicationDetails.applicationId,
      verticalName: reportData?.verticalName,
      fileName: summaryFile.fileName,
      fileType: '.docx',
      fileData: summaryFile.base64Transcript,
    }
    setShowToasterLoading(true);

    try {
      const response = await postUploadedSummaryTranscriptData(payload)
      if (response.status === 200) {
        setShowToasterPopup(true);
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setApplicationDetails((prevDetails: any) => ({
          ...prevDetails,
          applicationSummary: response.data.applicationSummary
        }));
        setAllApplicationDetails((prevDetails: any) => ({
          ...prevDetails,
          [applicationDetails.applicationId]: {
            ...prevDetails[applicationDetails.applicationId],
            applicationSummary: response.data.applicationSummary
          }
        }));
      }
      else {
        setIsLoading(false);
        let toastData = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        }
        setToastProp(toastData)
        setHideToast(false);
      }
    }
    catch (error) {
      setShowToasterFailedPopup(true);
      setIsLoading(false)
    } finally {
      setIsLoading(false);
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }

  }

  //PS_89 Define function to handle the Discovery section, including image upload logic and rendering of discovery details
  const Discovery = () => {
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const [sasToken, setSasToken] = useState<string>('');
    const [editFile, setEditFile] = useState<any>(null);
    const [editFileName, setEditFileName] = useState<string>("");
    const [editNameError, setEditNameError] = useState<string>("");
    const [editFileError, setEditFileError] = useState<string>("");
    const [showImageUploadModal, setShowImageUploadModal] = useState(false);
    const [isEditingSummary, setIsEditingSummary] = useState<boolean>(false);
    const [summaryValue, setSummaryValue] = useState<string>(applicationDetails?.applicationSummary || '');
    const [summaryFile, setSummaryFile] = useState<wafrFileInfoModel>(initialFileInfo)

    useEffect(() => {
      const fetchSasToken = async () => {
        try {
          const sas = await getSasToken('r');
          setSasToken(sas?.data);
        } catch (error) {
          console.error("Error fetching SAS token:", error);
        }
      };

      fetchSasToken();
    }, []);

    useEffect(() => {
      setSummaryValue(applicationDetails?.applicationSummary || '');
    }, [applicationDetails?.applicationSummary]);

    const generateImageUrl = (blobUrl: string, sasToken: string) => {
      if (!blobUrl || !sasToken) return '';
      const applicationId = applicationDetails.applicationId;
      const savedData = allApplicationDetails[applicationId] || {};
      const applicationArchitUrl = savedData.applicationArchitUrl || blobUrl;
      return `${blobUrl}?${sasToken}`;

    };

    const handleSummaryEditClick = () => {
      setIsEditingSummary(!isEditingSummary);
    };


    const handleAdd = async () => {
      let isValid = true;

      if (!applicationDetails.applicationName) {
        setNameError('Please enter an Architecture Name');
        isValid = false;
      } else if (applicationDetails.applicationName.length > 40) {
        setNameError('Please enter a valid Architecture Name');
        isValid = false;
      } else {
        setNameError('');
      }

      if (!file) {
        setFileError('Please select a file to upload');
        isValid = false;
      } else {
        setFileError('');
      }

      if (!isValid) return;
      setShowToasterLoading(true);

      try {
        const blobUrl = await uploadFileToBlob1(file, 'applicationArchitecture');

        const payload = {
          organizationId: reportData.organizationId,
          verticalId: reportData.verticalId,
          projectId: reportData.projectId,
          infraAssessmentMenuId: '',
          applicationId: applicationDetails.applicationId,
          applicationName: applicationDetails.applicationName,
          applicationSummary: applicationDetails.applicationSummary,
          applicationArchitectureBlobUrl: blobUrl
        };

        const response = await postInfraApplicationDetails(payload);
        if (response.data && response.status === 200) {
          setShowToasterPopup(true);
          setIsLoading(true);
          await new Promise(resolve => setTimeout(resolve, 1000));
          setApplicationDetails((prevDetails: any) => ({
            ...prevDetails,
            applicationArchitUrl: blobUrl,
          }));
          setAllApplicationDetails((prevDetails: any) => ({
            ...prevDetails,
            [applicationDetails.applicationId]: {
              ...prevDetails[applicationDetails.applicationId],
              applicationArchitUrl: blobUrl,
              applicationSummary: applicationDetails.applicationSummary
            }
          }));

          setFile(null);
          setFileName('');
        } else {
          setFileError("Error: " + response.statusText);
          setIsLoading(false);
          let toastData = {
            toastType: 'error',
            toastHeaderMessage: 'Error',
            toastBodyMessage: 'API failed'
          }
          setToastProp(toastData)
          setHideToast(false);
        }
      } catch (error) {
        setShowToasterFailedPopup(true);
        setFileError("An error occurred while adding the architecture details.");
      } finally {
        setIsLoading(false)
        setShowToasterLoading(false);
        setTimeout(() => {
          setShowToasterPopup(false);
          setShowToasterFailedPopup(false);
        }, 4000);
      }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, isEdit = false) => {
      const selectedFile = event.target.files?.[0];
      if (selectedFile) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!allowedTypes.includes(selectedFile.type)) {
          if (isEdit) {
            setEditFileError('File should be PNG, JPG, or JPEG');
            setEditFile(null);
            setEditFileName('');
          } else {
            setFileError('File should be PNG, JPG, or JPEG');
            setFile(null);
            setFileName('');
          }
          resetFileInput();
          return;
        }

        if (selectedFile.size > 20 * 1024 * 1024) {
          if (isEdit) {
            setEditFileError('File size should be less than 20 MB');
            setEditFile(null);
            setEditFileName('');
          } else {
            setFileError('File size should be less than 20 MB');
            setFile(null);
            setFileName('');
          }
          resetFileInput();
          return;
        }

        validateImage(selectedFile, 1000, 1500, 300)
          .then((result: any) => {
            if (result.isValid) {
              if (isEdit) {
                setEditFileError('');
                setEditFile(selectedFile);
                setEditFileName(selectedFile.name);
              } else {
                setFileError('');
                setFile(selectedFile);
                setFileName(selectedFile.name);
              }
            } else {
              if (isEdit) {
                setEditFileError(result.errorMessage);
                setEditFile(null);
                setEditFileName('');
              } else {
                setFileError(result.errorMessage);
                setFile(null);
                setFileName('');
              }
              resetFileInput();
            }
          })
          .catch((error) => {
            if (isEdit) {
              setEditFileError(error);
              setEditFile(null);
              setEditFileName('');
            } else {
              setFileError(error);
              setFile(null);
              setFileName('');
            }
            resetFileInput();
          });
      } else {
        if (isEdit) {
          setEditFile(null);
          setEditFileName('');
          setEditFileError('');
        } else {
          setFile(null);
          setFileName('');
          setFileError('');
        }
      }
    };

    const handleRemoveFile = () => {
      setFile(null);
      setFileName('');
      resetFileInput();
    };

    const handleCloseModal = () => {
      setShowImageUploadModal(false);
      setFileName('');
    };

    const handleCancel = () => {
      setShowImageUploadModal(false);
      setFileName('');
    };

    const onSummarySave = async (value: string) => {
      setShowToasterLoading(true);
      try {
        setSummaryValue(value);
        const payload = {
          organizationId: reportData?.organizationId,
          projectId: reportData?.projectId,
          verticalId: reportData?.verticalId,
          applicationId: applicationDetails.applicationId,
          applicationSummary: value,
          infraAssessmentMenuId: '',
          applicationName: applicationDetails.applicationName,
          applicationArchitectureBlobUrl: applicationDetails.applicationArchitUrl

        };
        const response = await postInfraApplicationDetails(payload);

        if (response.status === 200) {
          setShowToasterPopup(true);
          setIsLoading(true);
          await new Promise(resolve => setTimeout(resolve, 1000));
          setIsEditingSummary(false);
          setApplicationDetails((prevDetails: any) => ({
            ...prevDetails,
            applicationSummary: value,
          }));
          setAllApplicationDetails((prevDetails: any) => ({
            ...prevDetails,
            [applicationDetails.applicationId]: {
              ...prevDetails[applicationDetails.applicationId],
              applicationSummary: value
            }
          }));
        } else {
          throw new Error('Failed to update application summary');
        }
      } catch (error) {
        setIsLoading(false);
        let toastData = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        }
        setToastProp(toastData)
        setHideToast(false);
      } finally {
        setIsLoading(false)
        setShowToasterLoading(false);
        setTimeout(() => {
          setShowToasterPopup(false);
          setShowToasterFailedPopup(false);
        }, 4000);
      }
    };

    return (
      <div className="tab-pane fade show active" id="PortfolioDiscoverytab" role="tabpanel" >
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <h4 className="font-18 font-semibold color-black mt-3 pb-2">Discovery</h4>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="font-18 font-semibold color-black mt-4">APPLICATION SUMMARY</h5>
              {summaryValue && !isEditingSummary && (
                <button
                  type="button"
                  className="btn edit-btn custom-btn font-medium px-4"
                  onClick={handleSummaryEditClick}
                >
                  Edit
                </button>
              )}
            </div>
            {isEditingSummary ? (
              <TextEditor
                value={summaryValue}
                onChange={(value: string) => setSummaryValue(value)}
                onSave={onSummarySave}
                onCancel={handleSummaryEditClick}
                verticalName={reportData.verticalName}
                summaryType="Application Summary"

              />
            ) : applicationDetails?.applicationSummary ? (
              <div
                className="font-16 font-regular color-black my-3"
                dangerouslySetInnerHTML={{ __html: applicationDetails?.applicationSummary }}
              />

            ) : summaryValue ? (
              <div
                className="font-16 font-regular color-black my-3"
                dangerouslySetInnerHTML={{ __html: summaryValue }}
              />

            ) : (
              <UploadTranscript
                postSummaryTranscript={postSummaryTranscript}
                setFileInfo={setSummaryFile}
                fileInfo={summaryFile}
                showToasterPopup={showToasterPopup}
                showToasterFailedPopup={showToasterFailedPopup}
                type={"Map Application Summary"}
                hideToast={hideToast}
                toastProp={toastProp}
                showToasterLoading={showToasterLoading}
              />
            )}
            {/* <p className="font-16 font-regular color-black my-3">The {applicationDetails?.applicationName} by {reportData.organizationName} {applicationDetails?.applicationSummary}</p> */}
            <h5 className="font-18 font-semibold color-black mt-4">Template</h5>
            <p className="font-16 font-regular color-black my-3">
              The {applicationDetails?.applicationName} by {reportData.organizationName} is a dynamic solution crafted
              to streamline task organization, collaboration, and tracking for businesses of all sizes.
              It offers a centralized platform where teams can efficiently manage their tasks, deadlines,
              and priorities, fostering productivity and accountability. The Task Management Web
              Application is hosted on their {environmentType} server infrastructure.
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="font-18 font-semibold color-black mt-4">APPLICATION ARCHITECTURE</h5>
              <button type="button" className="btn btn-dark theme-outline-btn custom-btn font-medium px-4" onClick={() => setShowImageUploadModal(true)}>{applicationDetails.applicationArchitUrl ? "Reupload" : "Upload"}</button>
            </div>
            {applicationDetails?.applicationArchitUrl ?
              /* no image attached div starts here  */
              < div className="text-center py-5">
                <img src={generateImageUrl(applicationDetails.applicationArchitUrl, sasToken)} alt="image-notuploded" className="w-100 architect-hig" />
              </div> :
              /* no image attached div ends here  */
              <div className="text-center py-5">
                <img src="images/image-notuploded.svg" alt="image-notuploded" />
                <p className="my-3 font-16 font-medium color-grey">Application Architecture has not been uploaded</p>
              </div>
            }
            {showImageUploadModal && (
              <div className={`modal fade ${showImageUploadModal ? 'show' : ''}`} id="_addSessionPopup" style={{ display: showImageUploadModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content custom-popup">
                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                      <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor">Upload Architecture</h2>
                      <button type="button" className="btn" onClick={handleCloseModal} >
                        <img src="images/popup-close.svg" alt="Close" />
                      </button>
                    </div>
                    <div className="modal-body border-0 px-4">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label font-14 font-medium color-black" htmlFor="architectureUpload">
                            Upload Architecture
                          </label>
                          <div className="position-relative d-flex align-items-center">
                            <label
                              className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2"
                              htmlFor="architectureUpload"
                            >
                              <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                            </label>
                            <input
                              type="file"
                              className="form-control custom-file mb-4"
                              id="architectureUpload"
                              aria-label="upload"
                              onChange={handleFileChange}
                              accept=".png,.jpg,.jpeg"
                              style={{ opacity: 0 }}
                              ref={fileInputRef}
                            />
                            {fileName && (
                              <span className="font-12 font-medium color-grey ms-5">
                                {fileName}
                                <img
                                  src="images/close-icon-grey.svg"
                                  alt="close icon"
                                  className="ms-2 cursor-pointer"
                                  onClick={handleRemoveFile}
                                />
                              </span>
                            )}
                          </div>
                          {fileError && <div className="text-danger">{fileError}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                      <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={handleCancel}>Cancel</a>
                      <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleAdd} >Save</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* This will be used to cover the main content when the modal is displayed. It can be a separate div or existing logic */}
            {showImageUploadModal && (
              <div className="modal-backdrop fade show"></div>
            )}
            {showToasterLoading && (
              <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                  <div className="toast-body d-flex align-items-center ">
                    <div className="d-block me-3">
                      <img
                        src="images/loading.gif"
                        alt="toast-success"
                        style={{ height: "15px", width: "15px" }}
                      />
                    </div>
                    <div className="d-block">
                      <span className="font-medium font-16 color-grey">
                        Saving...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              showToasterPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                  <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                    <div className="toast-body d-flex align-items-center ">
                      <div className="d-block me-3">
                        <img src="images/toast-success.svg" alt="toast-success" />
                      </div>
                      <div className="d-block">
                        <span className="font-medium font-16 color-grey">
                          Saved Successfully
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                  <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                    <div className="toast-body d-flex align-items-center ">
                      <div className="d-block me-3">
                        <img src="images/fail-icon.svg" alt="toast-fail" />
                      </div>
                      <div className="d-block">
                        <span className="font-medium font-16 color-grey">
                          Api failed.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  //Define function to render Cloud Adoption Framework (CAF) section with chart display
  const Caf = () => {
    return (<div className="tab-pane fade show active" id="CAFtab" role="tabpanel" >
      <h3 className="font-24 color-black font-semibold">Cloud Adoption Framework</h3>
      <div className="col-lg-12 col-xl-12 col-md-12 col-12 my-4">
        <div className="p-4 border rounded-3">
          <p className="font-semibold font-18 font-semibold mb-4">Score Card</p>
          <div className=" w-100 d-flex justify-content-center align-items-center">
            {/* Line chart starts here */}
            <MapLineChart lineChartData={reportData.caf} />
            {/* Line chart ends here */}
          </div>
        </div>
      </div>
    </div>)
  }

  //Migration strategy Update 7R's 

  const BindApplicationDetails = () => {

    const [editedValues, setEditedValues] = useState<any>({});

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setEditedValues((prev: any) => {
        if (name === 'applicationStrategy') {
          // This part is triggered only when the applicationStrategy field changes
          const selectedStrategy = strategyList.find((strategy:any) => strategy.strategyName === value);
          return {
            ...prev,
            applicationStrategy: value,
            applicationStrategySummary: selectedStrategy ? selectedStrategy.strategySummary : '',
          };
        } else {
          // If the edited value is not applicationStrategy, we just return a copy of the
          // state with the updated field. The original applicationStrategy should remain unchanged.
          return {
            ...prev,
            [name]: value,
          };
        }
      });
    };

    const handleEditClick = (applicationId: string) => {
      setEditingId(applicationId);
      const itemToEdit = migrationStrategyData.find((item: any) => item.applicationId === applicationId);
      if (itemToEdit) {
        setEditedValues({
          applicationStrategy: itemToEdit.applicationStrategy,
          applicationStrategySummary: itemToEdit.applicationStrategySummary,
        });
      }
    };
    const handleSaveClick = async (item: any) => {
      setShowToasterLoading(true);
      const strategyName = editedValues.applicationStrategy || item.applicationStrategy;
      const strategySummary = editedValues.applicationStrategySummary !== undefined
        ? editedValues.applicationStrategySummary
        : item.applicationStrategySummary;

      const selectedStrategy = strategyList.find(
        (strategy: any) => strategy.strategyName === strategyName
      );

      if (!selectedStrategy) {
        setShowToasterFailedPopup(true);
        setShowToasterLoading(false);
        return;
      }

      const updatedData = migrationStrategyData.map((strategy: any) =>
        strategy.applicationId === item.applicationId
          ? {
            ...strategy,
            applicationStrategy: strategyName,
            applicationStrategySummary: strategySummary,
            strategyId: selectedStrategy.strategyId
          }
          : strategy
      );
      setMigrationStrategyData(updatedData);

      const payload = {
        strategyId: selectedStrategy.strategyId,
        strategySummary: strategySummary,
        applicationMigrationStrategyMappingId: item.applicationMigrationStrategyMappingId
      };

      try {
        const response = await UpdateMigrationStrategy(payload);

        if (response.status === 200) {
          const updatedData = migrationStrategyData.map((strategy: any) =>
            strategy.applicationId === item.applicationId
              ? {
                ...strategy,
                applicationStrategy: strategyName,
                applicationStrategySummary: strategySummary,
                strategyId: selectedStrategy.strategyId
              }
              : strategy
          );
          setMigrationStrategyData(updatedData);
          setEditingId(null);
          setEditedValues({});

          setShowToasterPopup(true); // Show success toaster
        } else {
          setShowToasterFailedPopup(true); // Show error toaster
        }
      } catch (error) {
        console.error("Error updating migration strategy:", error);
        setShowToasterFailedPopup(true); // Show error toaster
      } finally {
        setShowToasterLoading(false);
        setTimeout(() => {
          setShowToasterPopup(false);
          setShowToasterFailedPopup(false);
        }, 4000);
      }
    };


    const handleCancelClick = () => {
      setEditingId(null);
    };

    return (
      migrationStrategyData?.map((element: any, index: number) => {
        if (index < 4)
          return (
            <tr className="border-bottom" key={element.applicationId}>
              <td>{element.applicationName}</td>
              <td>
                {editingId === element.applicationId ? (


                  <select
                    name="applicationStrategy"
                    value={editedValues.applicationStrategy || element.applicationStrategy}
                    onChange={handleEditInputChange}
                    className="form-select"
                  >
                    <option value="" disabled>Select strategy (or leave empty)</option>
                    {strategyList.map((strategy: any) => (
                      <option key={strategy.strategyId} value={strategy.strategyName}>
                        {strategy.strategyName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <a href="#" className="link-blue text-decoration-none">{element.applicationStrategy}</a>
                )}
              </td>
              <td className="text-wrap">
                {editingId === element.applicationId ? (
                  <textarea
                    name="applicationStrategySummary"
                    value={editedValues.applicationStrategySummary !== undefined ? editedValues.applicationStrategySummary : element.applicationStrategySummary}
                    onChange={handleEditInputChange}
                    className="form-control"
                    rows={3}
                  />
                ) : (
                  element.applicationStrategySummary
                )}
              </td>
              <td>
                {editingId === element.applicationId ? (
                  <>
                    <span className="cursor-pointer" onClick={() => handleSaveClick(element)}>
                      <img src="images/check.svg" alt="saveIcon" title="Save" />
                    </span>
                    <span className="ms-3 cursor-pointer" onClick={handleCancelClick}>
                      <img src="images/cancel-icon.svg" alt="cancelIcon" title="Cancel" />
                    </span>
                  </>
                ) : (
                  <img
                    src="images/editIcon.svg"
                    alt="editIcon"
                    title="Edit"
                    onClick={() => handleEditClick(element.applicationId)}
                  />
                )}
              </td>
            </tr>
          )
      })
    )
  }


  //upload or reupload Recommended architecture

  const handleUploadButtonClick = () => {
    setShowImageUploadModal(true);
  };

  const handleCloseModal = () => {
    setShowImageUploadModal(false);
  };

  const RecomImageUploadModal = () => {
    return (
      <>
        <div className="modal-backdrop fade show"></div>
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content custom-popup">
              <ValidateRecomImageUploadModal />
            </div>
          </div>
        </div>
      </>
    );
  };


  const ValidateRecomImageUploadModal = () => {
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState('');

    const handleFileChange = (event: any) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!allowedTypes.includes(selectedFile.type)) {
          setError('File should be PNG, JPG, or JPEG');
          setFile(null);
          return;
        }

        if (selectedFile.size > 20 * 1024 * 1024) {
          setError('File size should be less than 20 MB');
          setFile(null);
          return;
        }

        setError('');
        setFile(selectedFile);
      } else {
        setFile(null);
        setError('');
      }
    };

    const handleUpload = async () => {
      handleCloseModal();
      if (!file) {
        setError('Please select a file to upload');
        return;
      }

      setError('');
      setShowToasterLoading(true);

      try {
        // Upload file to blob storage
        const blobUrl = await uploadFileToBlob1(file, 'recommendedarchitecture');

        // Prepare payload for API
        const payload = {
          infraAssessmentDetailsId: reportData.infraAssessmentDetailsId,
          organizationId: reportData.organizationId,
          verticalId: reportData.verticalId,
          projectId: reportData.projectId,
          recommendedArchitectureBlobUrl: blobUrl.toString()
        };

        const response = await PostRecommendedArchitecture(payload);


        if (response.status === 200) {

          setShowToasterPopup(true);
          // Update the reportData state with the new blobUrl
          setReportData((prevData: any) => ({
            ...prevData,
            migrationStrategy: {
              ...prevData.migrationStrategy,
              recommendedArchitBlodUrl: blobUrl, // set the new image URL here
              recommendedArchitUpdatedDate: new Date().toISOString(), // update the modification date if needed
            },
          }));

        } else {
          setError("Error: " + response.statusText);
        }
      } catch (error) {
        setShowToasterFailedPopup(true);
        setError("Error processing request");
        console.error("Error processing request", error);
      } finally {
        setShowToasterLoading(false);
        setTimeout(() => {
          setShowToasterPopup(false);
          setShowToasterFailedPopup(false);
        }, 4000);
      }
    };


    const handleRemoveFile = () => {
      setFile(null);
      setError(''); // Clear any errors.
    };

    return (
      <>
        <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
          <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
            Upload Architecture
          </h2>
          <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close">
            <img src="images/popup-close.svg" alt="Close" onClick={handleCloseModal} />
          </button>
        </div>
        <div className="modal-body border-0 px-4">
          <div className="row">
            <div className="col-md-12">
              <label className="form-label font-14 font-medium color-black" htmlFor="architectureUpload">
                Upload Architecture
              </label>
              <div className="position-relative d-flex align-items-center">
                <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2">
                  <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                </label>
                <input
                  type="file"
                  className="form-control custom-file mb-4"
                  id="architectureUpload"
                  aria-label="upload"
                  onChange={handleFileChange}
                  accept="image/png,image/jpeg,image/jpg"
                />
                {file && <span className="font-12 font-medium color-grey ms-5">{file.name}
                  <img
                    src="images/close-icon-grey.svg"
                    alt="close icon"
                    className="ms-2 cursor-pointer"
                    onClick={handleRemoveFile}
                  /></span>}
              </div>
              {error && <div className="text-danger">{error}</div>}
            </div>
          </div>
        </div>
        <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
          <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={handleCloseModal}>
            Cancel
          </a>
          <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleUpload}>
            Upload
          </button>
        </div>
      </>
    );
  };


  const MigrationStrategy = () => {

    const [sasToken, setSasToken] = useState<string>('');
    const [bloburl, setBloburl] = useState<any>(reportData?.migrationStrategy.recommendedArchitBlodUrl)

    useEffect(() => {
      const fetchSasToken = async () => {
        try {
          const sas = await getSasToken('r');
          setSasToken(sas?.data);
        } catch (error) {
          console.error("Error fetching SAS token:", error);
        }
      };

      fetchSasToken();
    }, []);

    const generateImageUrl = (blobUrl: string, sasToken: string) => {
      if (!blobUrl || !sasToken) return '';
      return `${blobUrl}?${sasToken}`;
    };


    return (
      <div className="tab-pane fade show active" id="MigrationStrategystab" role="tabpanel" >
        <h5 className="font-18 font-semibold color-black">Migration Strategy</h5>
        <div className="col-lg-12 col-xl-12 col-md-12 col-12 my-4">
          <div className="p-4 border rounded-3">
            <p className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
              Migration Strategy Table<img src="images/expand-icon.svg" alt="expand-icon" className="cursor-pointer" onClick={() => { setTableExpand(!tableExpand) }} data-bs-toggle="modal" data-bs-target="#recommendationsExapnMigrationStrategy" /></p>
            <div className=" w-100">
              <div className="table-responsive">
                <table className="table table-borderless themeTable mb-0">
                  <thead className="font-semibold sticky-top">
                    <tr>
                      <th>Application

                        <span className="ms-2 cursor-pointer" onClick={() => onClickSort("name")}>
                          {strategySortOrder.name ? " ↑" : " ↓"}
                        </span>

                      </th>
                      <th>7R</th>
                      <th>Summary
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {BindApplicationDetails()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-24 font-semibold color-black mt-5">Recommended Architecture</h3>
          <button type="button" className="btn btn-dark theme-outline-btn custom-btn font-medium px-4" onClick={() => setShowImageUploadModal(true)}>{reportData.recommendedArchitBlodUrl ? "Reupload" : "Upload"}</button>
        </div>

        {/* Recommended Architecture starts here */}
        {reportData?.migrationStrategy.recommendedArchitBlodUrl ?
          <div className="text-center py-5">
            <img src={generateImageUrl(reportData.migrationStrategy.recommendedArchitBlodUrl, sasToken)} alt="sample-architecture-blob-url-img" className="w-100 architect-hig" />
          </div> :
          <div className="text-center py-5">
            <img src="images/image-notuploded.svg" alt="image-notuploded" />
            <p className="my-3 font-16 font-medium color-grey">Architecture generation unsucessful, but you can still<br />upload the architecture manually</p>
          </div>
        }
        {showToasterLoading && (
          <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
              <div className="toast-body d-flex align-items-center ">
                <div className="d-block me-3">
                  <img
                    src="images/loading.gif"
                    alt="toast-success"
                    style={{ height: "15px", width: "15px" }}
                  />
                </div>
                <div className="d-block">
                  <span className="font-medium font-16 color-grey">
                    Saving...
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {
          showToasterPopup && (
            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
              <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                <div className="toast-body d-flex align-items-center ">
                  <div className="d-block me-3">
                    <img src="images/toast-success.svg" alt="toast-success" />
                  </div>
                  <div className="d-block">
                    <span className="font-medium font-16 color-grey">
                      Saved Successfully
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          showToasterFailedPopup && (
            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
              <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                <div className="toast-body d-flex align-items-center ">
                  <div className="d-block me-3">
                    <img src="images/fail-icon.svg" alt="toast-fail" />
                  </div>
                  <div className="d-block">
                    <span className="font-medium font-16 color-grey">
                      Api failed.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }


      </div>
    )
  }


  return (
    <div>
      <div className="container-fluid" style={{ marginTop: "105px" }}>
        <div className="row justify-content-center">
          <div className="px-4">
            <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-5">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <a href="" className="cursor-pointer">
                  <img
                    src="images/backarrow.svg"
                    alt="back-arrow"
                    title="Back"
                    onClick={(event: any) => {
                      event.preventDefault();
                      if (window.confirm("Are you sure you want to leave this page?")) {
                        navigate("/manageassessment", {
                          state: {
                            organizationId: initialReportData.organizationId,
                            organizationName: initialReportData.organizationName,
                          },
                        });
                      }
                    }}
                  />
                </a>
                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                  MAP Assessment
                </h2>
              </div>
              <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                {selectedNavBar === reportData.menus[3].menuName && (
                  <div className="btn-group" role="group">
                    <div className="btn-group w-100" role="group">
                      <select className="theme-form form-select font-regular font-14"
                        onChange={onClickApplicationDropDown}
                      // value={selectedApplicationId}
                      >
                        {bindApplicationDropdown()}
                      </select>
                    </div>
                  </div>
                )}
                {/* <span class="rounded-2 d-flex align-items-center justify-content-center  border-0 font-14 font-semibold severity-high-bg px-4"><span class="action-needed me-2"></span> Action Needed</span> */}
                {(isAzure || environmentType === "azure") && (
                  <button
                    type="button"
                    className="btn btn-dark theme-outline-btn d-flex gap-2 align-items-center font-14 font-semibold"
                    onClick={onSaveFile}
                  >
                    <img src="images/dwnld-icon-black.svg" alt="download-icon" />
                    Download Inventory
                  </button>
                )}
                <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 px-4 font-semibold " onClick={() => navigate("/previewpage", { state: { data: reportData } })}>
                  <span><img src="images/download-white.svg" alt="download-white" className="me-2" /></span>
                  Download Report
                </button>
              </div>
            </div>
            {/* heading content ends here */}
            <div className="row">
              <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                <div className="row">
                  <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                    <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <p className="font-16 font-semibold my-lg-4 my-2 ps-0 ps-lg-3">Prerequisite</p>
                      {/* Map Menus Nav Bar starts here */}
                      <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedNavBar === "uploadTranscript" ? "active" : ""}`} data-bs-toggle="pill" id="uploadTranscript" type="button" role="tab" aria-selected="true" onClick={(event: any) => { setSelectedNavBar(event.target.id) }}>Upload Transcript</button>
                      <p className="font-16 font-semibold my-lg-2  my-2 ps-0 ps-lg-3">Report</p>
                      {bindMapMenus()}
                      {/* Map Menus Nav Bar starts here */}
                    </div>
                  </div>
                  <select className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3">
                    <option selected>Executive Summary</option>
                    <option>Inventory & Summary</option>
                    <option>Portfolio Discovery</option>
                    <option>CAF</option>
                    <option>Migration Strategy</option>
                    <option>Migration Plan</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                <div className="row">
                  <div className="tab-content px-4" id="v-pills-tabContent">
                    {
                      selectedNavBar === "uploadTranscript" ? isLoading === true ? <Loader isLoading={true} /> :
                        <UploadTranscript
                          setFileInfo={setSummaryFile}
                          fileInfo={uploadedFile}
                          postTranscript={postUploadedTranscript.bind(null, "executiveSummary")}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                          showToasterLoading={showToasterLoading}
                          verticalName={reportData.verticalName} /> : <></>
                    }

                    {
                      selectedNavBar === "Executive Summary" ?
                        <ExecutiveSummary
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                          summaryName={"Executive Summary"}
                          summary={initialSummaryData?.executiveSummary}
                          fetchDirective={initialSummaryData?.directive}
                          fetchOutcome={initialSummaryData?.outcome}
                          postSummary={postSummary.bind(null, "executiveSummary")}
                          postDirective={postSummary.bind(null, "directive")}
                          postOutcome={postSummary.bind(null,"outcome")}
                          isExecutiveSummary={true}
                          organizationName={reportData.organizationName}
                          isAzure={reportData.isAzure}
                          verticalName={reportData.verticalName}
                        /> : <></>
                    }
                    {
                      selectedNavBar === "Business Overview" ?
                        <ExecutiveSummary
                          showToasterLoading={showToasterLoading}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                          summaryName={"Business Overview"}
                          summary={initialSummaryData.businessOverview}
                          verticalName={reportData.verticalName}
                          postSummary={postSummary.bind(null, "businessOverview")} />
                        : <></>
                    }
                    {selectedNavBar === "Inventory " ? <Inventory /> : <></>}
                    {selectedNavBar === "Portfolio Discovery " ? <Discovery /> : <></>}
                    {selectedNavBar === "CAF" ? <Caf /> : <></>}
                    {selectedNavBar === "Migration Strategy" ? <MigrationStrategy /> : <></>}
                    {
                      selectedNavBar === "Cost Breakdown" ? <CostBreakdown
                        initialReportData={initialReportData}
                        costBreakdownDetails={costBreakdownDetails}
                        setCostBreakdownDetails={setCostBreakdownDetails}
                        showToasterPopup={showToasterPopup}
                        showToasterFailedPopup={showToasterFailedPopup}
                        hideToast={hideToast}
                        toastProp={toastProp}
                        verticalName={reportData.verticalName}
                      /> : <></>
                    }
                    {
                      selectedNavBar === "Migration Plan" && reportData.verticalName === "MAP" ?
                        <MigrationPlan
                          state={reportData}
                          menuId={reportData.menuId}
                          setIsLoading={setIsLoading}
                          verticalName={reportData.verticalName}
                        /> : <></>
                    }
                    {selectedNavBar.trim() === "MAP Final Report Walkthrough" ? <ExecutiveSummary
                      showToasterPopup={showToasterPopup}
                      showToasterFailedPopup={showToasterFailedPopup}
                      hideToast={hideToast}
                      toastProp={toastProp}
                      summaryName={"WAFR Final Report Walkthrough"}
                      verticalName={"MAP"}
                      summary={initialSummaryData?.finalReportWalkthrough||""}
                      showToasterLoading={showToasterLoading}
                      postSummary={postSummary.bind(null, "finalReportWalkthrough")}
                      setFileInfo={setFinalWalkthroughFile} fileInfo={finalWalkthroughFile} postTranscript={postUploadedTranscript.bind(null, "finalReportWalkthrough")} />
                      :
                      <></>}
                  </div >
                </div >
              </div >
            </div >
          </div >
        </div >
      </div >
      {/* alert popup starts here */}
      <div className="modal fade" id="alert" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header pb-0 border-0">
              <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                Alert</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div>
                <p className="font-medium font-14">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione quae consequuntur aperiam tempora est. Harum!</p>
                <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                  <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4">Review What's Left</button>
                  <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4">Proceed Anyway</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* alert popup ends here */}
      <PopupComponent data={migrationStrategyData} />

      {
        showImageUploadModal && (
          <RecomImageUploadModal
          />
        )
      }
      <Loader isLoading={isLoading} />
    </div >
  )
}

export default InfrastructureMapReport
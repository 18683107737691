/**
 * PS_GR_1.1
 * import the neccessary components and packages
 */
import React, { useEffect, useState } from "react";
import HorizontalBarChart from "./reportComponents/HorizontalBarChart";
import DonutChart from "./reportComponents/DonutChart";
import GaugeChart from "./reportComponents/GaugeChart";
import AppLineChart from "./reportComponents/GraphLineChart";
import TableComponent from "./reportComponents/RecommendationGrid";
import VerticalChart from "./reportComponents/VerticalBarChart";
import { useNavigate, useLocation } from "react-router-dom";
import InfrastructureMapReport from "./InfrastructureMapReport";
import { getConnectorsData } from "../helpers/encryption";
import {
  ReportData,
  GaugeChartData,
  DropdownOption,
  GenReportProps,
  Recommendation,
} from "../interface/GenerateReportModel";
//import { encrypt, decrypt } from "../helpers/Encryption";
import {
  deleteDropDownOptionsApi,
  getDropDownProjects,
  getProjectReportData,
  getInfraMapReportDetails,
} from "../service/ReportApi";
import { postOrganizationStatus } from "../service/AssesmentApi";
import BreadCrumbs from "./breadCrumb";
import CommonReportTemp from "./reportComponents/CommonReportTemplate";
import ToastComponent from "./ToastComponent";
import { encryptStorage, fileNameSlice } from "../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getSasToken } from "../service/ReportApi";
import WafrReport from "./WafrReport";
import AwsMdpwReport from "./AwsMdpwReport";
import VMwareReport from "./VMwareReport";

const GenReport: React.FC<GenReportProps> = () => {
  /**
   * PS_GR_1.13
   * this useEffect check and call the functions
   * accordingly
   */
  useEffect(() => {
    if (location?.state?.backToReport) {
      setVertical(location?.state?.data?.verticalName);
      setProjectData(location?.state?.data);
      setReportData(location?.state?.data)
      setProject(location?.state?.data?.projectName);
      setProjectId(location?.state?.data?.projectId);
      setNoProject(false);
    } else if (location?.state?.organizationId) {
      // setVertical(reportData?.verticalName);
      projectReportData(body);
      projectDropdown();
    } else {
      navigate("/");
    }
  }, []);

  const toastData: any = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  /**
   * PS_GR_1.2 - PS_GR_1.10
   * state variables creation
   */
  const navigate = useNavigate();
  const [vertical, setVertical] = useState<string>();
  const [controlArea, setControlArea] = useState<string>("Overall Summary");
  let location = useLocation();
  const [reportData, setReportData] = useState<any>(location.state);
  const [loader, setLoader] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [project, setProject] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [projectDropDownData, setProjectDropDownData] = useState<any>([]);
  const [projectData, setProjectData] = useState<any>();
  const [ind, setInd] = useState<number>(-1);
  const [noProject, setNoProject] = useState<any>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<any>();
  const [sas, setSas] = useState<string>();
  const [noData, setNoData] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [toastProp, setToastProp] = useState<any>(toastData);
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [breadCrumbData, setBreadCrumbData] = useState([
    { title: "Organization", path: "/homepage", isActive: true },

    { title: reportData?.organizationName, path: "/manageassessment", isActive: true, state: { organizationId: reportData.organizationId, organizationName: reportData.organizationName }  },

    { title: reportData?.verticalName, path: "", isActive: true },

    { title: "Report", path: "", isActive: false },
  ]);

  const [intervalTime, setIntervalTime] = useState<number>(900);

  const [projectSelectBreadCrumbData, setProjectSelectBreadCrumbData] =
    useState([
      { title: "Assessments", path: "/homepage", isActive: true },

      { title: reportData?.organizationName, path: "", isActive: false },
    ]);

  /**PS_AG_15

  * This function is used to show and hide the toast message

  * hideToast state variable is passed as the dependency

  */

  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 20000);
  }, [hideToast]);

  /**
   * PS_GR_1.13 (SQ_1.2_1)
   * This useEffect sets the loader
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (percentage < 800) {
        setPercentage(percentage + 1);
      } else if (percentage === 800) {
        setLoader(false);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [percentage, intervalTime]);

  let body = {
    organizationId: reportData.organizationId,
    projectId: reportData.projectId,
    projectName: reportData.projectName,
    verticalId: reportData.verticalId,
    verticalName: reportData.verticalName,
    isViewReport: reportData?.isViewReport ? reportData?.isViewReport : false,
  };
  async function projectReportData(body: any) {
    try {
      setLoader(true);
      setPercentage(0);

      let response = await getProjectReportData(body);
      if (response.status == 200) {
        const updateProjectProgressReq = {
          organizationId: reportData.organizationId,
          projectId: reportData.projectId,
          progress: "Completed",
          verticalId: reportData.verticalId,
        };
        let responseOrg = await postOrganizationStatus(
          updateProjectProgressReq
        );
        if (responseOrg.status !== 200) {
          let toastData = {
            toastType: "error",
            toastHeaderMessage: "Error",
            toastBodyMessage: "Api Failed",
          };
          // setIntervalTime(10);
          setToastProp(toastData);
          setHideToast(false);
        }
        setPercentage(800);
        setProjectData(response.data);
        setProject(response?.data?.projectName);
        setVertical(response?.data?.verticalName);
        setProjectId(response?.data?.projectId);
        setNoProject(false);
        // setProjectData(response);
        // setProject(response?.projectName);
        // setVertical(response?.verticalName);
        // setProjectId(response?.projectId);
        // setNoProject(false);  
      } else {
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        // setIntervalTime(10);
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      // setIntervalTime(10);
      setPercentage(800);
      let toastData = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
    }
  }
  // //PC_MapInfraReportComp_15 - Function to call the api call to cloudgen
  // async function submitRecommendedArchitectureDetails() {
  //   if (location?.state?.mode === "view report") {
  //     try {
  //       setLoader(true);
  //       setPercentage(0);
  //       const sasToken = await generateSasToken()
  //       let response = await getInfraMapReportDetails({
  //         organizationId: location.state.organizationId,
  //         projectId: "",
  //         projectName: "",
  //         verticalId: location.state.verticalId,
  //         verticalName: location.state.verticalName,
  //         verticalAssessmentTypeId: location.state.verticalAssessmentTypeId,
  //         verticalAssessmentTypeName: location.state.verticalAssessmentTypeName
  //       })
  //       if (response.status === 200) {
  //         setReportData({ ...response.data, verticalName: location.state.verticalName, verticalAssessmentTypeName: location.state.verticalAssessmentTypeName, sasToken: sasToken })
  //         setVertical("Infrastructure")
  //         setIntervalTime(10);
  //       }
  //       else {
  //         setLoader(false)
  //         let toastData = {
  //           toastType: "error",
  //           toastHeaderMessage: "Error",
  //           toastBodyMessage: "Api Failed",
  //         };
  //         setIntervalTime(10);
  //         setToastProp(toastData);
  //         setHideToast(false);
  //       }
  //     }
  //     catch (error) {
  //       setLoader(false)
  //       let toastData = {
  //         toastType: "error",
  //         toastHeaderMessage: "Error",
  //         toastBodyMessage: "Api Failed",
  //       };
  //       setIntervalTime(10);
  //       setToastProp(toastData);
  //       setHideToast(false);
  //     }
  //   }
  //   else {
  //     try {
  //       setLoader(true);
  //       setPercentage(0);
  //       const sasToken = await generateSasToken()
  //       //PC_MapInfraReportComp_20 - Api call to fetch the report data
  //       let response = await getInfraMapReportDetails(
  //         {
  //           organizationId: location.state.organizationId,
  //           projectId: "",
  //           projectName: "",
  //           verticalId: location.state.verticalId,
  //           verticalName: location.state.verticalName,
  //           verticalAssessmentTypeId: location.state.verticalAssessmentTypeId,
  //           verticalAssessmentTypeName: location.state.verticalAssessmentTypeName
  //         });
  //       //PC_MapInfraReportComp_16 - Api call to submit the complete status
  //       if (response.status === 200) {
  //         setReportData({ ...response.data, verticalName: location.state.verticalName, verticalAssessmentTypeName: location.state.verticalAssessmentTypeName, sasToken: sasToken })
  //         setVertical("Infrastructure")
  //         const responseOfStatus = await postOrganizationStatus({ organizationId: location.state.organizationId, verticalId: location.state.verticalId, progress: "Completed" })
  //         if (responseOfStatus.status === 200) {
  //           setIntervalTime(10);
  //         }
  //         else {
  //           setLoader(false)
  //           let toastData = {
  //             toastType: "error",
  //             toastHeaderMessage: "Error",
  //             toastBodyMessage: "Api Failed",
  //           };
  //           setIntervalTime(10);
  //           setToastProp(toastData);
  //           setHideToast(false);
  //         }
  //       }
  //       else {
  //         setLoader(false)
  //         let toastData = {
  //           toastType: "error",
  //           toastHeaderMessage: "Error",
  //           toastBodyMessage: "Api Failed",
  //         };
  //         setIntervalTime(10);
  //         setToastProp(toastData);
  //         setHideToast(false);
  //       }
  //     }
  //     catch (error) {
  //       setIntervalTime(0);
  //       setLoader(false)
  //       let toastData = {
  //         toastType: "error",
  //         toastHeaderMessage: "Error",
  //         toastBodyMessage: "Api Failed",
  //       };
  //       setIntervalTime(10);
  //       setToastProp(toastData);
  //       setHideToast(false);
  //     }
  //   }
  // }

  /**
   * PS_GR_1.11
   * values to render the Gauge chart
   */
  const gaugeChart: GaugeChartData[] = [
    { name: "A", value: 33.3, color: "#ff796b" },
    { name: "B", value: 33.4, color: "#ffd772" },
    { name: "C", value: 33.3, color: "#78e07c" },
  ];

  /** PS_GR_1.16 - PS_GR_1.49
   * this merge the recommendations into an array
   * for each control areas and for each verticals
   * and sent the dontchart values for each vertical as well
   * and merge the controlAreas recommendation for each into one
   *  for each vertical overall summary
   */
  const mergedRecommendations: Recommendation[] = [];

  /**
   * App Dev vertical starts
   */
  const modularityOverall: Recommendation[] = [];
  const servicesArchitectureOverall: Recommendation[] = [];
  const eventBasedArchitectureOverall: Recommendation[] = [];
  const loggingOverall: Recommendation[] = [];
  const integrationOverall: Recommendation[] = [];
  const platformOverall: Recommendation[] = [];
  const apiStrategyOverall: Recommendation[] = [];

  if (vertical == "Application Architecture") {
    const modularityRecommendations =
      projectData?.controlAreaReport?.modularity?.recommendations;
    for (const key in modularityRecommendations) {
      modularityOverall.push(...modularityRecommendations[key]);
    }

    const servicesArchitectureRecommendations =
      projectData?.controlAreaReport?.servicesArchitecture?.recommendations;
    for (const key in servicesArchitectureRecommendations) {
      servicesArchitectureOverall.push(
        ...servicesArchitectureRecommendations[key]
      );
    }

    const eventBasedArchitectureRecommendations =
      projectData?.controlAreaReport?.eventBasedArchitecture?.recommendations;
    for (const key in eventBasedArchitectureRecommendations) {
      eventBasedArchitectureOverall.push(
        ...eventBasedArchitectureRecommendations[key]
      );
    }

    const loggingRecommendations =
      projectData?.controlAreaReport?.logging?.recommendations;
    for (const key in loggingRecommendations) {
      loggingOverall.push(...loggingRecommendations[key]);
    }

    const integrationRecommendations =
      projectData?.controlAreaReport?.integration?.recommendations;
    for (const key in integrationRecommendations) {
      integrationOverall.push(...integrationRecommendations[key]);
    }

    const platformRecommendations =
      projectData?.controlAreaReport?.platform?.recommendations;
    for (const key in platformRecommendations) {
      platformOverall.push(...platformRecommendations[key]);
    }

    const apiStrategyRecommendations =
      projectData?.controlAreaReport?.apiStrategy?.recommendations;
    for (const key in apiStrategyRecommendations) {
      apiStrategyOverall.push(...apiStrategyRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      modularityOverall,
      servicesArchitectureOverall,
      eventBasedArchitectureOverall,
      loggingOverall,
      integrationOverall,
      platformOverall,
      apiStrategyOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const modularityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.modularity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.modularity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.modularity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const serviceArchitectureDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.servicesArchitecture?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.servicesArchitecture?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.servicesArchitecture?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const eventBasedArchitectureDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.eventBasedArchitecture?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.eventBasedArchitecture?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.eventBasedArchitecture?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const loggingDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.logging?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.logging?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.logging?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const integrationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.integration?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.integration?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.integration?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const platformDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.platform?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.platform?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.platform?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const apiStrategyDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.apiStrategy?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.apiStrategy?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.apiStrategy?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //app dev vertical ends

  /**
   * Mobility vertical starts
   */
  const userExperienceOverall: Recommendation[] = [];
  const performanceMonitoringOverall: Recommendation[] = [];
  const codebaseArchitectureOverall: Recommendation[] = [];
  const deviceandBuildSecurityOverall: Recommendation[] = [];
  const userandAppIdentityOverall: Recommendation[] = [];

  if (vertical == "Mobility") {
    const userExperienceRecommendations =
      projectData?.controlAreaMenuReport?.userExperience?.recommendations;
    for (const key in userExperienceRecommendations) {
      userExperienceOverall.push(...userExperienceRecommendations[key]);
    }

    const performanceMonitoringRecommendations =
      projectData?.controlAreaMenuReport?.performanceMonitoring
        ?.recommendations;
    for (const key in performanceMonitoringRecommendations) {
      performanceMonitoringOverall.push(
        ...performanceMonitoringRecommendations[key]
      );
    }

    const codebaseArchitectureRecommendations =
      projectData?.controlAreaMenuReport?.codebaseArchitecture?.recommendations;
    for (const key in codebaseArchitectureRecommendations) {
      codebaseArchitectureOverall.push(
        ...codebaseArchitectureRecommendations[key]
      );
    }

    const deviceandBuildSecurityRecommendations =
      projectData?.controlAreaMenuReport?.deviceAndBuildSecurity
        ?.recommendations;
    for (const key in deviceandBuildSecurityRecommendations) {
      deviceandBuildSecurityOverall.push(
        ...deviceandBuildSecurityRecommendations[key]
      );
    }

    const userandAppIdentityRecommendations =
      projectData?.controlAreaMenuReport?.userAndAppIdentityManagement
        ?.recommendations;
    for (const key in userandAppIdentityRecommendations) {
      userandAppIdentityOverall.push(...userandAppIdentityRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      userExperienceOverall,
      performanceMonitoringOverall,
      codebaseArchitectureOverall,
      deviceandBuildSecurityOverall,
      userandAppIdentityOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const userExperienceDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.userExperience?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.userExperience?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.userExperience?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const performanceMonitoringDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.performanceMonitoring?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.performanceMonitoring?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.performanceMonitoring?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const codebaseArchitectureDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.codebaseArchitecture?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.codebaseArchitecture?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.codebaseArchitecture?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const deviceandBuildSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.deviceAndBuildSecurity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.deviceAndBuildSecurity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.deviceAndBuildSecurity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const userandAppIdentityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.userAndAppIdentityManagement?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.userAndAppIdentityManagement?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.userAndAppIdentityManagement?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //mobility vertical end

  //DevSecOps VERTICAL STARTS

  const releaseManagementOverall: Recommendation[] = [];
  const ciAndDeliveryOverall: Recommendation[] = [];
  const codeManagementOverall: Recommendation[] = [];
  const accessMangamentOverall: Recommendation[] = [];
  const monitoringAndIncidentOverall: Recommendation[] = [];
  const compilanceAndGovOverall: Recommendation[] = [];

  if (vertical == "DevSecOps") {
    const releaseManagementRecommendations =
      projectData?.controlAreaReport?.releaseManagement?.recommendations;
    for (const key in releaseManagementRecommendations) {
      releaseManagementOverall.push(...releaseManagementRecommendations[key]);
    }

    const ciAndDeliveryRecommendations =
      projectData?.controlAreaReport
        ?.continuousIntegrationAndContinuousDevelopementPipelines
        ?.recommendations;
    for (const key in ciAndDeliveryRecommendations) {
      ciAndDeliveryOverall.push(...ciAndDeliveryRecommendations[key]);
    }

    const codeManagementRecommendations =
      projectData?.controlAreaReport?.codeManagementAndVersionControl
        ?.recommendations;
    for (const key in codeManagementRecommendations) {
      codeManagementOverall.push(...codeManagementRecommendations[key]);
    }

    const accessMangamentRecommendations =
      projectData?.controlAreaReport?.accessManagementAndPrivilegedControl
        ?.recommendations;
    for (const key in accessMangamentRecommendations) {
      accessMangamentOverall.push(...accessMangamentRecommendations[key]);
    }

    const monitoringAndIncidentRecommendations =
      projectData?.controlAreaReport?.monitoringAndIncidentResponse
        ?.recommendations;
    for (const key in monitoringAndIncidentRecommendations) {
      monitoringAndIncidentOverall.push(
        ...monitoringAndIncidentRecommendations[key]
      );
    }

    const compilanceAndGovRecommendations =
      projectData?.controlAreaReport?.complianceAndGovernance?.recommendations;
    for (const key in compilanceAndGovRecommendations) {
      compilanceAndGovOverall.push(...compilanceAndGovRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      releaseManagementOverall,
      ciAndDeliveryOverall,
      codeManagementOverall,
      accessMangamentOverall,
      monitoringAndIncidentOverall,
      compilanceAndGovOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const releaseManagementDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.releaseManagement?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.releaseManagement?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.releaseManagement?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const ciAndDeliveryDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.continuousIntegrationAndContinuousDevelopementPipelines?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.continuousIntegrationAndContinuousDevelopementPipelines?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.continuousIntegrationAndContinuousDevelopementPipelines?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const codeManagementDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.codeManagementAndVersionControl?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.codeManagementAndVersionControl?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.codeManagementAndVersionControl?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const accessMangamentDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.accessManagementAndPrivilegedControl?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.accessManagementAndPrivilegedControl?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.accessManagementAndPrivilegedControl?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const monitoringAndIncidentDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.monitoringAndIncidentResponse?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.monitoringAndIncidentResponse?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.monitoringAndIncidentResponse?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const compilanceAndGovDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.complianceAndGovernance?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.complianceAndGovernance?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.complianceAndGovernance?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //DevSecOps vertical ends

  //qa VERTICAL STARTS

  const requirementAnalysisOverall: Recommendation[] = [];
  const testCoverageOverall: Recommendation[] = [];
  const workflowManagementOverall: Recommendation[] = [];
  const testCaseAndTestScriptDevOverall: Recommendation[] = [];
  const defectValidationOverall: Recommendation[] = [];
  const testEnvironmentOverall: Recommendation[] = [];
  const functionalTestingOverall: Recommendation[] = [];
  const nonFunctionalTestingOverall: Recommendation[] = [];

  if (vertical == "QA Processes") {
    const requirementAnalysisRecommendations =
      projectData?.controlAreaReport?.requirementAnalysis?.recommendations;
    for (const key in requirementAnalysisRecommendations) {
      requirementAnalysisOverall.push(
        ...requirementAnalysisRecommendations[key]
      );
    }

    const testCoverageRecommendations =
      projectData?.controlAreaReport?.testCoverage?.recommendations;
    for (const key in testCoverageRecommendations) {
      testCoverageOverall.push(...testCoverageRecommendations[key]);
    }

    const workflowManagementRecommendations =
      projectData?.controlAreaReport?.workFlowManagement?.recommendations;
    for (const key in workflowManagementRecommendations) {
      workflowManagementOverall.push(...workflowManagementRecommendations[key]);
    }

    const testCaseAndTestScriptDevRecommendations =
      projectData?.controlAreaReport?.testCaseAndTestScriptDevelop
        ?.recommendations;
    for (const key in testCaseAndTestScriptDevRecommendations) {
      testCaseAndTestScriptDevOverall.push(
        ...testCaseAndTestScriptDevRecommendations[key]
      );
    }

    const defectValidationOverallRecommendations =
      projectData?.controlAreaReport?.defectValidation?.recommendations;
    for (const key in defectValidationOverallRecommendations) {
      defectValidationOverall.push(
        ...defectValidationOverallRecommendations[key]
      );
    }

    const testEnvironmentRecommendations =
      projectData?.controlAreaReport?.testEnvironment?.recommendations;
    for (const key in testEnvironmentRecommendations) {
      testEnvironmentOverall.push(...testEnvironmentRecommendations[key]);
    }

    const functionalTestingRecommendations =
      projectData?.controlAreaReport?.functionalTesting?.recommendations;
    for (const key in functionalTestingRecommendations) {
      functionalTestingOverall.push(...functionalTestingRecommendations[key]);
    }

    const nonFunctionalTestingRecommendations =
      projectData?.controlAreaReport?.nonFunctionalTesting?.recommendations;
    for (const key in nonFunctionalTestingRecommendations) {
      nonFunctionalTestingOverall.push(
        ...nonFunctionalTestingRecommendations[key]
      );
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      requirementAnalysisOverall,
      testCoverageOverall,
      workflowManagementOverall,
      testCaseAndTestScriptDevOverall,
      defectValidationOverall,
      testEnvironmentOverall,
      functionalTestingOverall,
      nonFunctionalTestingOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const requirementAnalysisDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.requirementAnalysis?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.requirementAnalysis?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.requirementAnalysis?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const testCoverageDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.testCoverage?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.testCoverage?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.testCoverage?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const workflowManagementDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.workFlowManagement?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.workFlowManagement?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.workFlowManagement?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const testCaseAndTestScriptDevDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.testCaseAndTestScriptDevelop?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.testCaseAndTestScriptDevelop?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.testCaseAndTestScriptDevelop?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const defectValidationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.defectValidation?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.defectValidation?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.defectValidation?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const testEnvironmentDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.testEnvironment?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.testEnvironment?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.testEnvironment?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const functionalTestingDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.functionalTesting?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.functionalTesting?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.functionalTesting?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const nonFunctionalTestingDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.nonFunctionalTesting?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.nonFunctionalTesting?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.nonFunctionalTesting?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //qa vertical ends

  //dev process VERTICAL STARTS

  const defineOverall: Recommendation[] = [];
  const designOverall: Recommendation[] = [];
  const developmentOverall: Recommendation[] = [];

  if (vertical == "Development Processes") {
    const defineRecommendations =
      projectData?.controlAreaReport?.define?.recommendations;
    for (const key in defineRecommendations) {
      defineOverall.push(...defineRecommendations[key]);
    }

    const designRecommendations =
      projectData?.controlAreaReport?.design?.recommendations;
    for (const key in designRecommendations) {
      designOverall.push(...designRecommendations[key]);
    }

    const developmentRecommendations =
      projectData?.controlAreaReport?.development?.recommendations;
    for (const key in developmentRecommendations) {
      developmentOverall.push(...developmentRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      defineOverall,
      designOverall,
      developmentOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const defineDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.define?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.define?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.define?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const designDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.design?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.design?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.design?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const developmentDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.development?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.development?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.development?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];
  //dev process vertical ends

  //Enterprice Application (m-365) VERTICAL STARTS

  const governanceOverall: Recommendation[] = [];
  const compilanceOverall: Recommendation[] = [];
  const securityAuditOverall: Recommendation[] = [];
  const siteHierarchyOverall: Recommendation[] = [];
  const customAppsOverall: Recommendation[] = [];

  if (vertical == "Enterprise Applications" || vertical == "MS O365") {
    const governanceRecommendations =
      projectData?.controlAreaReport?.governance?.recommendations;
    for (const key in governanceRecommendations) {
      governanceOverall.push(...governanceRecommendations[key]);
    }

    const compilanceRecommendations =
      projectData?.controlAreaReport?.compliance?.recommendations;
    for (const key in compilanceRecommendations) {
      compilanceOverall.push(...compilanceRecommendations[key]);
    }

    const securityAuditRecommendations =
      projectData?.controlAreaReport?.securityAudit?.recommendations;
    for (const key in securityAuditRecommendations) {
      securityAuditOverall.push(...securityAuditRecommendations[key]);
    }

    const siteHierarchyRecommendations =
      projectData?.controlAreaReport?.siteHierarchy?.recommendations;
    for (const key in siteHierarchyRecommendations) {
      siteHierarchyOverall.push(...siteHierarchyRecommendations[key]);
    }

    const customAppsRecommendations =
      projectData?.controlAreaReport?.customApps?.recommendations;
    for (const key in customAppsRecommendations) {
      customAppsOverall.push(...customAppsRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      governanceOverall,
      compilanceOverall,
      securityAuditOverall,
      siteHierarchyOverall,
      customAppsOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const governanceDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.governance?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.governance?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.governance?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const compilanceDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.compliance?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.compliance?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.compliance?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const securityAuditDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.securityAudit?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.securityAudit?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.securityAudit?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const siteHierarchyDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.siteHierarchy?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.siteHierarchy?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.siteHierarchy?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const customAppsDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.customApps?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.customApps?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.customApps?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //Enterprice Application (m-365) vertical ends

  //Copilot VERTICAL STARTS

  const copilotReadinessOverall: Recommendation[] = [];

  if (vertical == "Copilot") {
    const copilotReadinessRecommendations =
      projectData?.controlAreaReport?.copilotReadiness?.recommendations;
    for (const key in copilotReadinessRecommendations) {
      copilotReadinessOverall.push(...copilotReadinessRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [copilotReadinessOverall];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const copilotReadinessDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.copilotReadiness?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.copilotReadiness?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.copilotReadiness?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //Copilot vertical ends

  /**
   * infra vertical starts
   */

  const securityOverall: Recommendation[] = [];
  const costOptimizationOverall: Recommendation[] = [];
  const reliabilityOverall: Recommendation[] = [];
  const operationalExcellenceOverall: Recommendation[] = [];
  const performanceEfficiencyOverall: Recommendation[] = [];

  if (vertical == "Infrastructure") {
    const securityRecommendations =
      projectData?.controlAreaMenuReport?.security?.recommendations;
    for (const key in securityRecommendations) {
      securityOverall.push(...securityRecommendations[key]);
    }

    const costOptimizationRecommendations =
      projectData?.controlAreaMenuReport?.costOptimization?.recommendations;
    for (const key in costOptimizationRecommendations) {
      costOptimizationOverall.push(...costOptimizationRecommendations[key]);
    }

    const reliabilityRecommendations =
      projectData?.controlAreaMenuReport?.reliability?.recommendations;
    for (const key in reliabilityRecommendations) {
      reliabilityOverall.push(...reliabilityRecommendations[key]);
    }

    const operationalExcellenceRecommendations =
      projectData?.controlAreaMenuReport?.operationalExcellence
        ?.recommendations;
    for (const key in operationalExcellenceRecommendations) {
      operationalExcellenceOverall.push(
        ...operationalExcellenceRecommendations[key]
      );
    }

    const performanceEfficiencyRecommendations =
      projectData?.controlAreaMenuReport?.performanceEfficiency
        ?.recommendations;
    for (const key in performanceEfficiencyRecommendations) {
      performanceEfficiencyOverall.push(
        ...performanceEfficiencyRecommendations[key]
      );
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      securityOverall,
      costOptimizationOverall,
      reliabilityOverall,
      operationalExcellenceOverall,
      performanceEfficiencyOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const securityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.security?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.security?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.security?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const costOptimizationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.costOptimization?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.costOptimization?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.costOptimization?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const reliabilityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.reliability?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.reliability?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.reliability?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const operationalExcellenceDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.operationalExcellence?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.operationalExcellence?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.operationalExcellence?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const performanceEfficiencyDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.performanceEfficiency?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.performanceEfficiency?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.performanceEfficiency?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //infra vertical end

  /**
   * data stra vertical starts
   */

  const transactionalDatabaseOverall: Recommendation[] = [];
  const noSqlDatabaseOverall: Recommendation[] = [];
  const dataLakeAndDataWarehouseOverall: Recommendation[] = [];
  const reportingAndAnalysisOverall: Recommendation[] = [];
  const aIAndMLOverall: Recommendation[] = [];

  if (vertical == "Data Strategy") {
    const transactionalDatabaseRecommendations =
      projectData?.controlAreaMenuReport?.transactionalDatabase
        ?.recommendations;
    for (const key in transactionalDatabaseRecommendations) {
      transactionalDatabaseOverall.push(
        ...transactionalDatabaseRecommendations[key]
      );
    }

    const noSqlDatabaseRecommendations =
      projectData?.controlAreaMenuReport?.noSqlDatabase?.recommendations;
    for (const key in noSqlDatabaseRecommendations) {
      noSqlDatabaseOverall.push(...noSqlDatabaseRecommendations[key]);
    }

    const dataLakeAndDataWarehouseRecommendations =
      projectData?.controlAreaMenuReport?.dataLakeAndDataWarehouse
        ?.recommendations;
    for (const key in dataLakeAndDataWarehouseRecommendations) {
      dataLakeAndDataWarehouseOverall.push(
        ...dataLakeAndDataWarehouseRecommendations[key]
      );
    }

    const reportingAndAnalysisRecommendations =
      projectData?.controlAreaMenuReport?.reportingAndAnalysis?.recommendations;
    for (const key in reportingAndAnalysisRecommendations) {
      reportingAndAnalysisOverall.push(
        ...reportingAndAnalysisRecommendations[key]
      );
    }

    const aIAndMLRecommendations =
      projectData?.controlAreaMenuReport?.AIAndML?.recommendations;
    for (const key in aIAndMLRecommendations) {
      aIAndMLOverall.push(...aIAndMLRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      transactionalDatabaseOverall,
      noSqlDatabaseOverall,
      dataLakeAndDataWarehouseOverall,
      reportingAndAnalysisOverall,
      aIAndMLOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const transactionalDatabaseDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.transactionalDatabase?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.transactionalDatabase?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.transactionalDatabase?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const noSqlDatabaseDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.noSqlDatabase?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.noSqlDatabase?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.noSqlDatabase?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const dataLakeAndDataWarehouseDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.dataLakeAndDataWarehouse?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.dataLakeAndDataWarehouse?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.dataLakeAndDataWarehouse?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const reportingAndAnalysisDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.reportingAndAnalysis?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.reportingAndAnalysis?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.reportingAndAnalysis?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const aIAndMLDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.AIAndML?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.AIAndML?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.AIAndML?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //data stra vertical end

  /**
   * pdp starts here
   */
  const proofOfConceptOverall: Recommendation[] = [];
  const productDesignOverall: Recommendation[] = [];
  const prototypingOverall: Recommendation[] = [];
  const productDevelopmentOverall: Recommendation[] = [];
  const goToMarketOverall: Recommendation[] = [];

  if (vertical == "Product Development Processes") {
    const proofOfConceptRecommendations =
      projectData?.controlAreaReport?.proofOfConcept?.recommendations;
    for (const key in proofOfConceptRecommendations) {
      proofOfConceptOverall.push(...proofOfConceptRecommendations[key]);
    }

    const productDesignRecommendations =
      projectData?.controlAreaReport?.productDesign?.recommendations;
    for (const key in productDesignRecommendations) {
      productDesignOverall.push(...productDesignRecommendations[key]);
    }

    const prototypingRecommendations =
      projectData?.controlAreaReport?.prototyping?.recommendations;
    for (const key in prototypingRecommendations) {
      prototypingOverall.push(...prototypingRecommendations[key]);
    }

    const productDevelopmentRecommendations =
      projectData?.controlAreaReport?.productDevelopment?.recommendations;
    for (const key in productDevelopmentRecommendations) {
      productDevelopmentOverall.push(...productDevelopmentRecommendations[key]);
    }

    const goToMarketRecommendations =
      projectData?.controlAreaReport?.goToMarket?.recommendations;
    for (const key in goToMarketRecommendations) {
      goToMarketOverall.push(...goToMarketRecommendations[key]);
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      proofOfConceptOverall,
      productDesignOverall,
      prototypingOverall,
      productDevelopmentOverall,
      goToMarketOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const proofOfConceptDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.proofOfConcept?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.proofOfConcept?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.proofOfConcept?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const productDesignDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.productDesign?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.productDesign?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.productDesign?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const prototypingDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.prototyping?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.prototyping?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.prototyping?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const productDevelopmentDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.productDevelopment?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.productDevelopment?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.productDevelopment?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const goToMarketDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.goToMarket?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.goToMarket?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.goToMarket?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //pdp vertical end

  /**
   * IAM starts here
   */
  const identityAndAccessManagementOverall: Recommendation[] = [];
  const emailSecurityOverall: Recommendation[] = [];
  const endpointSecurityOverall: Recommendation[] = [];
  const applicationSecurityOverall: Recommendation[] = [];
  const networkSecurityOverall: Recommendation[] = [];
  const dataProtectionOverall: Recommendation[] = [];
  const operationalRiskManagementOverall: Recommendation[] = [];

  if (vertical == "IAM & Security") {
    const identityAndAccessManagementRecommendations =
      projectData?.controlAreaMenuReport?.identityAndAccessManagement
        ?.recommendations;
    for (const key in identityAndAccessManagementRecommendations) {
      identityAndAccessManagementOverall.push(
        ...identityAndAccessManagementRecommendations[key]
      );
    }

    const emailSecurityRecommendations =
      projectData?.controlAreaMenuReport?.emailSecurity?.recommendations;
    for (const key in emailSecurityRecommendations) {
      emailSecurityOverall.push(...emailSecurityRecommendations[key]);
    }

    const endpointSecurityRecommendations =
      projectData?.controlAreaMenuReport?.endpointSecurity?.recommendations;
    for (const key in endpointSecurityRecommendations) {
      endpointSecurityOverall.push(...endpointSecurityRecommendations[key]);
    }

    const applicationSecurityRecommendations =
      projectData?.controlAreaMenuReport?.applicationSecurity?.recommendations;
    for (const key in applicationSecurityRecommendations) {
      applicationSecurityOverall.push(
        ...applicationSecurityRecommendations[key]
      );
    }

    const networkSecurityRecommendations =
      projectData?.controlAreaMenuReport?.networkSecurity?.recommendations;
    for (const key in networkSecurityRecommendations) {
      networkSecurityOverall.push(...networkSecurityRecommendations[key]);
    }

    const dataProtectionRecommendations =
      projectData?.controlAreaMenuReport?.dataProtection?.recommendations;
    for (const key in dataProtectionRecommendations) {
      dataProtectionOverall.push(...dataProtectionRecommendations[key]);
    }

    const operationalRiskManagementRecommendations =
      projectData?.controlAreaMenuReport?.operationalRiskManagement
        ?.recommendations;
    for (const key in operationalRiskManagementRecommendations) {
      operationalRiskManagementOverall.push(
        ...operationalRiskManagementRecommendations[key]
      );
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      identityAndAccessManagementOverall,
      emailSecurityOverall,
      endpointSecurityOverall,
      applicationSecurityOverall,
      networkSecurityOverall,
      dataProtectionOverall,
      operationalRiskManagementOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const identityAndAccessDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.identityAndAccessManagement?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )?.length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.identityAndAccessManagement?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )?.length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.identityAndAccessManagement?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )?.length,
    },
  ];

  const emailSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.emailSecurity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.emailSecurity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.emailSecurity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const endpointSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.endpointSecurity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.endpointSecurity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.endpointSecurity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const applicationSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.applicationSecurity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.applicationSecurity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.applicationSecurity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const networkSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.networkSecurity?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.networkSecurity?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.networkSecurity?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const dataProtectionDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.dataProtection?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.dataProtection?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.dataProtection?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const operationalRiskManagementDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaMenuReport?.operationalRiskManagement?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaMenuReport?.operationalRiskManagement?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaMenuReport?.operationalRiskManagement?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const activeUsersDonutChart: DonutChartData[] = [
    {
      name: "activeGuestsUser",
      value:
        projectData?.identityAndAccessManagementMetric?.activeGuestsUser || 0,
    },
    {
      name: "activeInternalUser",
      value:
        projectData?.identityAndAccessManagementMetric?.activeInternalUser || 0,
    },
  ];
  const inActiveUsersDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value: projectData?.identityAndAccessManagementMetric?.inActiveGuestsUser,
    },
    {
      name: "Group B",
      value:
        projectData?.identityAndAccessManagementMetric?.inActiveInternalUser,
    },
  ];

  const riskyUsersDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value: projectData?.identityAndAccessManagementMetric?.highRiskyUsers,
    },
    {
      name: "Group B",
      value: projectData?.identityAndAccessManagementMetric?.mediumRiskyUsers,
    },
    {
      name: "Group B",
      value: projectData?.identityAndAccessManagementMetric?.lowRiskyUsers,
    },
  ];

  //IAM vertical end

  /**
   * Salesforce starts here
   */
  const businessProcessOverall: Recommendation[] = [];
  const automationOverall: Recommendation[] = [];
  const environmentDetailsOverall: Recommendation[] = [];
  const salesforceSecurityOverall: Recommendation[] = [];
  const integrationAndCustomizationOverall: Recommendation[] = [];

  if (vertical == "Salesforce") {
    const businessProcessRecommendations =
      projectData?.controlAreaReport?.businessProcess?.recommendations;
    for (const key in businessProcessRecommendations) {
      businessProcessOverall.push(...businessProcessRecommendations[key]);
    }

    const automationRecommendations =
      projectData?.controlAreaReport?.automation?.recommendations;
    for (const key in automationRecommendations) {
      automationOverall.push(...automationRecommendations[key]);
    }

    const environmentDetailsRecommendations =
      projectData?.controlAreaReport?.environmentDetails?.recommendations;
    for (const key in environmentDetailsRecommendations) {
      environmentDetailsOverall.push(...environmentDetailsRecommendations[key]);
    }

    const salesforceSecurityRecommendations =
      projectData?.controlAreaReport?.security?.recommendations;
    for (const key in salesforceSecurityRecommendations) {
      salesforceSecurityOverall.push(...salesforceSecurityRecommendations[key]);
    }

    const integrationAndCustomizationRecommendations =
      projectData?.controlAreaReport?.integrationAndCustomization
        ?.recommendations;
    for (const key in integrationAndCustomizationRecommendations) {
      integrationAndCustomizationOverall.push(
        ...integrationAndCustomizationRecommendations[key]
      );
    }

    /** the below for merge all the Control Area recommendations into one */
    const recommendationArrays: Recommendation[][] = [
      businessProcessOverall,
      automationOverall,
      environmentDetailsOverall,
      salesforceSecurityOverall,
      integrationAndCustomizationOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const businessProcessDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.businessProcess?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.businessProcess?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.businessProcess?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const automationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const environmentDetailsDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const salesforceSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const integrationAndCustomizationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.integrationAndCustomization?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.integrationAndCustomization?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.integrationAndCustomization?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //Salesforce vertical end

  /**
   * serviceNow starts here
   */

  const serviceNowEnvironmentDetailsOverall: Recommendation[] = [];
  const serviceNowProcessOverall: Recommendation[] = [];
  const CSMOverall: Recommendation[] = [];
  const HRSDOverall: Recommendation[] = [];
  const IRMOverall: Recommendation[] = [];
  const ITAMOverall: Recommendation[] = [];
  const ITOMOverall: Recommendation[] = [];
  const ITSMOverall: Recommendation[] = [];
  const portalOverall: Recommendation[] = [];
  const secOpsOverall: Recommendation[] = [];
  const SPMOverall: Recommendation[] = [];
  const serviceNowSecurityOverall: Recommendation[] = [];
  const serviceNowAutomationOverall: Recommendation[] = [];
  const serviceNowIntegrationAndCustomizationOverall: Recommendation[] = [];

  if (vertical == "ServiceNow") {
    const serviceNowEnvironmentDetailsRecommendations =
      projectData?.controlAreaReport?.environmentDetails?.recommendations;
    for (const key in serviceNowEnvironmentDetailsRecommendations) {
      serviceNowEnvironmentDetailsOverall.push(
        ...serviceNowEnvironmentDetailsRecommendations[key]
      );
    }

    const serviceNowProcessRecommendations =
      projectData?.controlAreaReport?.process?.recommendations;
    for (const key in serviceNowProcessRecommendations) {
      serviceNowProcessOverall.push(...serviceNowProcessRecommendations[key]);
    }

    const CSMRecommendations =
      projectData?.controlAreaReport?.CSM?.recommendations;
    for (const key in CSMRecommendations) {
      CSMOverall.push(...CSMRecommendations[key]);
    }
    const HRSDRecommendations =
      projectData?.controlAreaReport?.HRSD?.recommendations;
    for (const key in HRSDRecommendations) {
      HRSDOverall.push(...HRSDRecommendations[key]);
    }
    const IRMRecommendations =
      projectData?.controlAreaReport?.IRM?.recommendations;
    for (const key in IRMRecommendations) {
      IRMOverall.push(...IRMRecommendations[key]);
    }
    const ITAMRecommendations =
      projectData?.controlAreaReport?.ITAM?.recommendations;
    for (const key in ITAMRecommendations) {
      ITAMOverall.push(...ITAMRecommendations[key]);
    }
    const ITOMRecommendations =
      projectData?.controlAreaReport?.ITOM?.recommendations;
    for (const key in ITOMRecommendations) {
      ITOMOverall.push(...ITOMRecommendations[key]);
    }
    const ITSMRecommendations =
      projectData?.controlAreaReport?.ITSM?.recommendations;
    for (const key in ITSMRecommendations) {
      ITSMOverall.push(...ITSMRecommendations[key]);
    }
    const portalRecommendations =
      projectData?.controlAreaReport?.portal?.recommendations;
    for (const key in portalRecommendations) {
      portalOverall.push(...portalRecommendations[key]);
    }
    const secOpsRecommendations =
      projectData?.controlAreaReport?.secOps?.recommendations;
    for (const key in secOpsRecommendations) {
      secOpsOverall.push(...secOpsRecommendations[key]);
    }
    const SPMRecommendations =
      projectData?.controlAreaReport?.spm?.recommendations;
    for (const key in SPMRecommendations) {
      SPMOverall.push(...SPMRecommendations[key]);
    }

    const serviceNowSecurityRecommendations =
      projectData?.controlAreaReport?.security?.recommendations;
    for (const key in serviceNowSecurityRecommendations) {
      serviceNowSecurityOverall.push(...serviceNowSecurityRecommendations[key]);
    }

    const serviceNowAutomationRecommendations =
      projectData?.controlAreaReport?.automation?.recommendations;
    for (const key in serviceNowAutomationRecommendations) {
      serviceNowAutomationOverall.push(
        ...serviceNowAutomationRecommendations[key]
      );
    }

    const serviceNowIntegrationAndCustomizationRecommendations =
      projectData?.controlAreaReport?.integrationCustomization?.recommendations;
    for (const key in serviceNowIntegrationAndCustomizationRecommendations) {
      serviceNowIntegrationAndCustomizationOverall.push(
        ...serviceNowIntegrationAndCustomizationRecommendations[key]
      );
    }

    const recommendationArrays: Recommendation[][] = [
      serviceNowEnvironmentDetailsOverall,
      serviceNowProcessOverall,
      // CSMOverall,
      // HRSDOverall,
      // IRMOverall,
      // ITAMOverall,
      // ITOMOverall,
      // ITSMOverall,
      // portalOverall,
      // secOpsOverall,
      // SPMOverall,
      serviceNowSecurityOverall,
      serviceNowAutomationOverall,
      serviceNowIntegrationAndCustomizationOverall,
    ];

    for (const overallRecommendations of recommendationArrays) {
      mergedRecommendations.push(...overallRecommendations);
    }
  }

  const serviceNowEnvironmentDetailsDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.environmentDetails?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const serviceNowProcessDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.process?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.process?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.process?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const CSMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value: projectData?.controlAreaReport?.CSM?.recommendations?.high?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.CSM?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.CSM?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const HRSDDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.HRSD?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.HRSD?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.HRSD?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const IRMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value: projectData?.controlAreaReport?.IRM?.recommendations?.high?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.IRM?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.IRM?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const ITAMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.ITAM?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.ITAM?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.ITAM?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const ITOMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.ITOM?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.ITOM?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.ITOM?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const ITSMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.ITSM?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.ITSM?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.ITSM?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const portalDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.portal?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.portal?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.portal?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const secOpsDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.secOps?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.secOps?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.secOps?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const SPMDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value: projectData?.controlAreaReport?.spm?.recommendations?.high?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.spm?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value: projectData?.controlAreaReport?.spm?.recommendations?.low?.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length,
    },
  ];

  const serviceNowSecurityDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.security?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const serviceNowAutomationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.automation?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  const serviceNowIntegrationAndCustomizationDonutChart: DonutChartData[] = [
    {
      name: "Group A",
      value:
        projectData?.controlAreaReport?.integrationCustomization?.recommendations?.high?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group B",
      value:
        projectData?.controlAreaReport?.integrationCustomization?.recommendations?.medium?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
    {
      name: "Group C",
      value:
        projectData?.controlAreaReport?.integrationCustomization?.recommendations?.low?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        ).length,
    },
  ];

  //serviceNow vertical end

  /**
   * Returns data for Donut chart and recommendations for overall summary
   */
  const sortImpactData = () => {
    const impactOrder: { [key in Recommendation["impact"]]: number } = {
      High: 3,
      Medium: 2,
      Low: 1,
    };

    const sortedData: Recommendation[] = mergedRecommendations.sort(
      (a, b) => impactOrder[b.impact] - impactOrder[a.impact]
    );

    return sortedData;
  };

  const sortedData1 = sortImpactData();

  const sortedData = sortedData1.filter(
    (item: any) =>
      item.description !== null &&
      item.description !== undefined &&
      item.description !== ""
  );

  const highImpactCount = sortedData.filter(
    (item) => item.impact === "High"
  ).length;
  const mediumImpactCount = sortedData.filter(
    (item) => item.impact === "Medium"
  ).length;
  const lowImpactCount = sortedData.filter(
    (item) => item.impact === "Low"
  ).length;

  const donutChart: DonutChartData[] = [
    { name: "Group A", value: highImpactCount },
    { name: "Group B", value: mediumImpactCount },
    { name: "Group C", value: lowImpactCount },
  ];

  /**
   * PS_GR_1.53 end of the vertical for recommendations
   */

  /**
   * PS_GR_1.54 - PS_GR_1.56
   * this bind the buttons for control areas
   */
  function bindButton() {
    return projectData?.controlAreaNames?.map(
      (item: { controlAreaName: string }, index: number) => (
        <button
          key={index}
          className={
            controlArea === item.controlAreaName
              ? "nav-link text-start rounded-0 font-regular font-14 adjust-height active"
              : "nav-link text-start rounded-0 font-regular adjust-height font-14"
          }
          id="v-pills-overallSumm-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-overallSumm"
          type="button"
          role="tab"
          aria-controls="v-pills-overallSumm"
          aria-selected="true"
          onClick={() => {
            setControlArea(item.controlAreaName);
          }}
          title={item.controlAreaName}
        >
          {/* {item.controlAreaName.length > 26
            ? item.controlAreaName.slice(0, 23) + "..."
            : item.controlAreaName} */}
          {item.controlAreaName}
        </button>
      )
    );
  }

  // function bindProcessForServiceNow(){
  //   return projectData?.processControlAreaNames?.map((item:{controlAreaName: string}, index: number)=>(
  //     <button
  //     key={index}
  //     className="nav-link text-start rounded-0 font-medium font-14 "
  //     id="v-pills-overallSumm-tab"
  //     data-bs-toggle="pill"
  //     data-bs-target="#v-pills-overallSumm"
  //     type="button"
  //     role="tab"
  //     aria-controls="v-pills-overallSumm"
  //     aria-selected="true"
  //     onClick={() => {
  //       setControlArea(item.controlAreaName);
  //     }}
  //     title={item.controlAreaName}
  //   >
  //     {item.controlAreaName}
  //   </button>
  //   ))
  // }

  function bindDropdownButtons() {
    return projectData?.controlAreaNames?.map(
      (item: { controlAreaName: string }, index: number) => (
        <option key={index} value={item.controlAreaName}>
          {item.controlAreaName}
        </option>
      )
    );
  }

  /**
   * PS_GR_1.55
   * this function makes the api call to get the projects
   */
  async function projectDropdown() {
    let body = {
      organizationId: reportData.organizationId,
      verticalId: reportData.verticalId,
    };
    let response: any = await getDropDownProjects(body);
    setProjectDropDownData(response.data);
  }

  /**
   * PS_GR_1.58 - PS_GR_1.63
   * this function check the project if
   * it is completed or not if not it does not
   * make an api to to get another project report
   * @param selectedPid
   */
  const handleDropdownClick = async (selectedPid: string) => {
    if (selectedPid !== projectId) {
      setProjectDropDownData(projectDropDownData);
      const selectedProject = projectDropDownData.find(
        (project: any) => project.projectId === selectedPid
      );
      if (selectedProject && selectedProject.progress === "Completed") {
        let body = {
          organizationId: reportData.organizationId,
          projectId: selectedPid,
          projectName: selectedProject.projectName,
          verticalId: reportData.verticalId,
          verticalName: reportData.verticalName,
        };
        setNoProject(false);
        // setLoader(true);
        // setPercentage(0);
        setNoData(false);
        await projectReportData(body);
      } else {
        setProjectId(selectedPid);
        setProject(selectedProject.projectName);
        setControlArea("Overall Summary");
        setNoData(true);
      }
    }
  };

  /**
   * PS_GR_1.66 - PS_GR_1.82
   * this functions deletes the project and
   * navigate the user respectivley
   * @param selectedProjectId
   */
  const deleteDropDownOption = async (selectedProjectId: string) => {
    let response = await deleteDropDownOptionsApi(selectedProjectId);

    if (selectedProjectId == projectId && projectDropDownData.length == 1) {
      navigate("/assessment", {
        state: {
          organizationId: reportData.organizationId,
          verticalId: reportData.verticalId,
          organizationName: reportData.organizationName,
          verticalName: reportData.verticalName,
          mode: "assess",
        },
      });
    } else if (selectedProjectId == projectId) {
      navigate("/homepage");
    } else {
      await projectDropdown();
    }
  };

  /**
   * PS_GR_1.83
   * @returns this function only return the HTML to get
   * the id of the project the user clicked on
   */
  function projectDropdownOptions() {
    return projectDropDownData.map((value: any, index: number) => (
      <li
        className="dropdown-item custom-dropdown-li ps-1 pe-4"
        id={value.projectId}
        onClick={(e: any) => {
          e.stopPropagation();
          setActive(false);
          handleDropdownClick(e.target.id);
        }}
        onMouseEnter={() => setInd(index)}
        onMouseLeave={() => setInd(-1)}
      >
        <span
          key={value?.projectId}
          id={value?.projectId}
          onClick={(e: any) => {
            e.stopPropagation();
            setActive(false);
            handleDropdownClick(e.target.id);
          }}
          onMouseEnter={() => setInd(index)}
          onMouseLeave={() => setInd(-1)}
        >
          {value?.projectName}
        </span>
        {/* <ul
          className="dropdown-menu py-1 m-0"
          style={ind == index ? { display: "block" } : { display: "none" }}
        >
           <li
            className="dropdown-item custom-dropdown-li p-1"
            data-bs-toggle="modal"
            data-bs-target="#delete"
            id={value.projectId}
            onClick={(e: any) => {
              e.stopPropagation();
              setSelectedProjectId(e.target.id);
            }}
          >
            <a
              id={value.projectId}
              className="color-red font-14 font-medium text-decoration-none"
            >
              Delete
            </a>
          </li>
        </ul> */}
      </li>
    ));
  }
  const ModularitySonarCloudChart = [
    {
      name: "LinesOfCode",
      value: projectData?.codeAnalysis?.size?.linesOfCode,
    },
    { name: "LinesOfCode", value: projectData?.codeAnalysis?.size?.statements },
    { name: "LinesOfCode", value: projectData?.codeAnalysis?.size?.functions },
    { name: "LinesOfCode", value: projectData?.codeAnalysis?.size?.classes },
    { name: "LinesOfCode", value: projectData?.codeAnalysis?.size?.files },
    {
      name: "LinesOfCode",
      value: projectData?.codeAnalysis?.size?.commentLines,
    },
  ];

  interface DonutChartData {
    name: string;
    value: number;
  }

  let appDevPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    projectName: project,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    modularityDonutchart: modularityDonutChart,
    modularitySonarCloudChart: ModularitySonarCloudChart,
    modularityRecom: modularityOverall,
    servicesArchitectureRecom: servicesArchitectureOverall,
    serviceArchitectureDonutChart: serviceArchitectureDonutChart,
    eventBasedArchitectureDonutChart: eventBasedArchitectureDonutChart,
    eventBasedArchitectureRecom: eventBasedArchitectureOverall,
    loggingDonutChart: loggingDonutChart,
    loggingRecom: loggingOverall,
    integrationDonutChart: integrationDonutChart,
    integrationRecom: integrationOverall,
    platformDonutChart: platformDonutChart,
    platformRecom: platformOverall,
    apiStrategyDonutChart: apiStrategyDonutChart,
    apiStrategyRecom: apiStrategyOverall,
  };

  let mobilityPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    projectName: project,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    userExperienceDonutChart: userExperienceDonutChart,
    userExperienceOverall: userExperienceOverall,
    performanceMonitoringDonutChart: performanceMonitoringDonutChart,
    performanceMonitoringOverall: performanceMonitoringOverall,
    codebaseArchitectureDonutChart: codebaseArchitectureDonutChart,
    codebaseArchitectureOverall: codebaseArchitectureOverall,
    deviceandBuildSecurityDonutChart: deviceandBuildSecurityDonutChart,
    deviceandBuildSecurityOverall: deviceandBuildSecurityOverall,
    userandAppIdentityDonutChart: userandAppIdentityDonutChart,
    userandAppIdentityOverall: userandAppIdentityOverall,
  };

  let devSecOpsPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    releaseManagementDonutChart: releaseManagementDonutChart,
    releaseManagementOverall: releaseManagementOverall,
    ciAndDeliveryDonutChart: ciAndDeliveryDonutChart,
    ciAndDeliveryOverall: ciAndDeliveryOverall,
    codeManagementDonutChart: codeManagementDonutChart,
    codeManagementOverall: codeManagementOverall,
    accessMangamentDonutChart: accessMangamentDonutChart,
    accessMangamentOverall: accessMangamentOverall,
    monitoringAndIncidentDonutChart: monitoringAndIncidentDonutChart,
    monitoringAndIncidentOverall: monitoringAndIncidentOverall,
    compilanceAndGovDonutChart: compilanceAndGovDonutChart,
    compilanceAndGovOverall: compilanceAndGovOverall,
  };
  let qaProcessPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    testEnvironmentDonutChart: testEnvironmentDonutChart,
    testEnvironmentOverall: testEnvironmentOverall,
    requirementAnalysisDonutChart: requirementAnalysisDonutChart,
    requirementAnalysisOverall: requirementAnalysisOverall,
    nonFunctionalTestingDonutChart: nonFunctionalTestingDonutChart,
    nonFunctionalTestingOverall: nonFunctionalTestingOverall,
    workflowManagementDonutChart: workflowManagementDonutChart,
    workflowManagementOverall: workflowManagementOverall,
    functionalTestingDonutChart: functionalTestingDonutChart,
    functionalTestingOverall: functionalTestingOverall,
    testCoverageDonutChart: testCoverageDonutChart,
    testCoverageOverall: testCoverageOverall,
    defectValidationDonutChart: defectValidationDonutChart,
    defectValidationOverall: defectValidationOverall,
    testCaseAndTestScriptDevDonutChart: testCaseAndTestScriptDevDonutChart,
    testCaseAndTestScriptDevOverall: testCaseAndTestScriptDevOverall,
  };
  let devlopmentProcessPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    defineDonutChart: defineDonutChart,
    defineOverall: defineOverall,
    designDonutChart: designDonutChart,
    designOverall: designOverall,
    developmentDonutChart: developmentDonutChart,
    developmentOverall: developmentOverall,
  };
  let enterpriseAppPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    compilanceDonutChart: compilanceDonutChart,
    compilanceOverall: compilanceOverall,
    securityAuditDonutChart: securityAuditDonutChart,
    securityAuditOverall: securityAuditOverall,
    siteHierarchyDonutChart: siteHierarchyDonutChart,
    siteHierarchyOverall: siteHierarchyOverall,
    customAppsDonutChart: customAppsDonutChart,
    customAppsOverall: customAppsOverall,
    governanceDonutChart: governanceDonutChart,
    governanceOverall: governanceOverall,
  };
  let copilotReadinessPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    copilotReadinessDonutChart: copilotReadinessDonutChart,
    copilotReadinessOverall: copilotReadinessOverall,
  };
  let infraPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    operationalExcellenceDonutChart: operationalExcellenceDonutChart,
    operationalExcellenceOverall: operationalExcellenceOverall,
    securityDonutChart: securityDonutChart,
    securityOverall: securityOverall,
    costOptimizationDonutChart: costOptimizationDonutChart,
    costOptimizationOverall: costOptimizationOverall,
    reliabilityDonutChart: reliabilityDonutChart,
    reliabilityOverall: reliabilityOverall,
    performanceEfficiencyDonutChart: performanceEfficiencyDonutChart,
    performanceEfficiencyOverall: performanceEfficiencyOverall,
  };

  let dataStraPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    transactionalDatabaseDonutChart: transactionalDatabaseDonutChart,
    transactionalDatabaseOverall: transactionalDatabaseOverall,
    noSqlDatabaseDonutChart: noSqlDatabaseDonutChart,
    noSqlDatabaseOverall: noSqlDatabaseOverall,
    dataLakeAndDataWarehouseDonutChart: dataLakeAndDataWarehouseDonutChart,
    dataLakeAndDataWarehouseOverall: dataLakeAndDataWarehouseOverall,
    reportingAndAnalysisDonutChart: reportingAndAnalysisDonutChart,
    reportingAndAnalysisOverall: reportingAndAnalysisOverall,
    aIAndMLDonutChart: aIAndMLDonutChart,
    aIAndMLOverall: aIAndMLOverall,
  };

  let pdpPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    proofOfConceptDonutChart: proofOfConceptDonutChart,
    proofOfConceptOverall: proofOfConceptOverall,
    productDesignDonutChart: productDesignDonutChart,
    productDesignOverall: productDesignOverall,
    prototypingDonutChart: prototypingDonutChart,
    prototypingOverall: prototypingOverall,
    productDevelopmentDonutChart: productDevelopmentDonutChart,
    productDevelopmentOverall: productDevelopmentOverall,
    goToMarketDonutChart: goToMarketDonutChart,
    goToMarketOverall: goToMarketOverall,
  };

  let IAMPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    isAzureADConnected: projectData?.isAzureADConnected,
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    activeUsersDonutChartData: activeUsersDonutChart,
    inActiveUsersDonutChartData: inActiveUsersDonutChart,
    riskyUsersDonuChartData: riskyUsersDonutChart,
    identityAndAccessDonutChart: identityAndAccessDonutChart,
    emailSecurityDonutChart: emailSecurityDonutChart,
    endpointSecurityDonutChart: endpointSecurityDonutChart,
    applicationSecurityDonutChart: applicationSecurityDonutChart,
    networkSecurityDonutChart: networkSecurityDonutChart,
    dataProtectionDonutChart: dataProtectionDonutChart,
    operationalRiskManagementDonutChart: operationalRiskManagementDonutChart,
    identityAndAccessManagementRecom: identityAndAccessManagementOverall,
    endpointSecurityRecom: endpointSecurityOverall,
    emailSecurityRecom: emailSecurityOverall,
    applicationSecurityRecom: applicationSecurityOverall,
    networkSecurityRecom: networkSecurityOverall,
    dataProtectionRecom: dataProtectionOverall,
    operationalRiskManagementRecom: operationalRiskManagementOverall,
  };

  let salesforcePreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    businessProcessDonutChart: businessProcessDonutChart,
    businessProcessRecom: businessProcessOverall,
    automationDonutChart: automationDonutChart,
    automationRecom: automationOverall,
    environmentDetailsDonutChart: environmentDetailsDonutChart,
    environmentDetailsRecom: environmentDetailsOverall,
    salesforceSecurityDonutChart: salesforceSecurityDonutChart,
    salesforceSecurityRecom: salesforceSecurityOverall,
    integrationAndCustomizationDonutChart:
      integrationAndCustomizationDonutChart,
    integrationAndCustomizationRecom: integrationAndCustomizationOverall,
  };

  let serviceNowPreviewReportData = {
    overallHorizonatalBarChart: projectData?.controlAreaProficiencyIndex,
    overallDonuchart: donutChart,
    GaugeValue: gaugeChart,
    overallGaugeChart: projectData?.overallProficiencyIndex,
    overallRecommendation: sortedData,
    controlAreas: projectData?.controlAreaNames,
    organizationId: reportData.organizationId,
    verticalName: reportData.verticalName,
    data: projectData,
    impact: {
      highImpactCount,
      mediumImpactCount,
      lowImpactCount,
    },
    overAllRecommendationss: projectData?.overallRecommendations?.description,
    businessProcessDonutChart: businessProcessDonutChart,
    serviceNowEnvironmentDetailsRecom: serviceNowEnvironmentDetailsOverall,
    serviceNowProcessRecom: serviceNowProcessOverall,
    CSMRecom: CSMOverall,
    HRSDRecom: HRSDOverall,
    IRMRecom: IRMOverall,
    ITAMRecom: ITAMOverall,
    ITOMRecom: ITOMOverall,
    ITSMRecom: ITSMOverall,
    portalRecom: portalOverall,
    secOpsRecom: secOpsOverall,
    SPMRecom: SPMOverall,
    serviceNowSecurityRecom: serviceNowSecurityOverall,
    serviceNowAutomationRecom: serviceNowAutomationOverall,
    serviceNowIntegrationAndCustomizationRecom:
      serviceNowIntegrationAndCustomizationOverall,
    serviceNowEnvironmentDetailsDonutChart:
      serviceNowEnvironmentDetailsDonutChart,
    serviceNowProcessDonutChart: serviceNowProcessDonutChart,
    CSMDonutChart: CSMDonutChart,
    HRSDDonutChart: HRSDDonutChart,
    IRMDonutChart: IRMDonutChart,
    ITAMDonutChart: ITAMDonutChart,
    ITOMDonutChart: ITOMDonutChart,
    ITSMDonutChart: ITSMDonutChart,
    portalDonutChart: portalDonutChart,
    secOpsDonutChart: secOpsDonutChart,
    SPMDonutChart: SPMDonutChart,
    serviceNowSecurityDonutChart: serviceNowSecurityDonutChart,
    serviceNowAutomationDonutChart: serviceNowAutomationDonutChart,
    serviceNowIntegrationAndCustomizationDonutChart:
      serviceNowIntegrationAndCustomizationDonutChart,
  };
  
  const InfrastructureReportComponent = () => {
    if (location?.state?.verticalName) {
      // setReportData(reportData)
      //return <InfrastructureMapReport reportData={reportData} />;
    } else {
      return (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: infraPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {noData && (
                    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                      <p className="color-grey font-18 font-semibold text-center mt-5">
                        No input configurations specified to view this report
                      </p>
                      <p
                        onClick={() => {
                          navigate("/assessment", {
                            state: {
                              organizationId: reportData.organizationId,
                              verticalId: reportData.verticalId,
                              organizationName: reportData.organizationName,
                              verticalName: reportData.verticalName,
                              mode: "assess",
                            },
                          });
                        }}
                        className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                      >
                        Start Assigning Now
                        <img
                          className="ms-2"
                          src="images/blue-arrow.svg"
                          alt="arrow"
                        />
                      </p>
                    </div>
                  )}
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Operational Excellence" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Operational Excellence"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.operationalExcellence?.controlAreaBarChart
                      }
                      donutChart={operationalExcellenceDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.operationalExcellence?.overallRecommendations
                          ?.description
                      }
                      gridData={operationalExcellenceOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.security
                          ?.controlAreaBarChart
                      }
                      donutChart={securityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.security
                          ?.overallRecommendations?.description
                      }
                      gridData={securityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Cost Optimization" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Cost Optimization"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.costOptimization
                          ?.controlAreaBarChart
                      }
                      donutChart={costOptimizationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.costOptimization
                          ?.overallRecommendations?.description
                      }
                      gridData={costOptimizationOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Reliability" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Reliability"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.reliability
                          ?.controlAreaBarChart
                      }
                      donutChart={reliabilityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.reliability
                          ?.overallRecommendations?.description
                      }
                      gridData={reliabilityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Performance Efficiency" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Performance Efficiency"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.performanceEfficiency?.controlAreaBarChart
                      }
                      donutChart={performanceEfficiencyDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.performanceEfficiency?.overallRecommendations
                          ?.description
                      }
                      gridData={performanceEfficiencyOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  /**
   * PS_GR_1.83
   * returns the html to render the report for
   * each vertical
   */
  return (
    <>
      {/**Assessment Summary verticals */}
      {vertical == "Application Architecture" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <div className="btn-group" role="group">
                        <div className="btn-group w-100" role="group">
                          <button
                            type="button"
                            className={`theme-form text-start custom-dropdown-toggle groupbtn add-proj-btn bg-white border-grey ps-2 pe-4 font-14 font-medium ${active ? "show" : ""
                              }`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {project}
                          </button>
                          <ul className="dropdown-menu bg-white font-14 font-medium group-dropdown px-1 w-100">
                            {projectDropdownOptions()}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: appDevPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 pe-0">
                    <div className="d-lg-flex d-none align-items-start v-tab-border pe-0 left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold mb-lg-3 my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {noData && (
                    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                      <p className="color-grey font-18 font-semibold text-center mt-5">
                        No input configurations specified to view this report
                      </p>
                      <p
                        onClick={() => {
                          navigate("/assessment", {
                            state: {
                              organizationId: reportData.organizationId,
                              verticalId: reportData.verticalId,
                              organizationName: reportData.organizationName,
                              verticalName: reportData.verticalName,
                              mode: "assess",
                            },
                          });
                        }}
                        className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                      >
                        Start Assigning Now
                        <img
                          className="ms-2"
                          src="images/blue-arrow.svg"
                          alt="arrow"
                        />
                      </p>
                    </div>
                  )}
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Modularity" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Modularity"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.modularity
                          ?.controlAreaBarChart
                      }
                      donutChart={modularityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.modularity
                          ?.overallRecommendations?.description
                      }
                      gridData={modularityOverall}
                      gridArea={true}
                      codeAnalysisData={projectData?.codeAnalysis}
                      modularitySonarCloudChart={ModularitySonarCloudChart}
                    />
                  )}
                  {controlArea === "Services Architecture" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Services Architecture"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.servicesArchitecture
                          ?.controlAreaBarChart
                      }
                      donutChart={serviceArchitectureDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.servicesArchitecture
                          ?.overallRecommendations?.description
                      }
                      gridData={servicesArchitectureOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Event Based Architecture" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Event Based Architecture"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.eventBasedArchitecture
                          ?.controlAreaBarChart
                      }
                      donutChart={eventBasedArchitectureDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.eventBasedArchitecture
                          ?.overallRecommendations?.description
                      }
                      gridData={eventBasedArchitectureOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Logging" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Logging"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.logging
                          ?.controlAreaBarChart
                      }
                      donutChart={loggingDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.logging
                          ?.overallRecommendations?.description
                      }
                      gridData={loggingOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Integration" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Integration"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.integration
                          ?.controlAreaBarChart
                      }
                      donutChart={integrationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.integration
                          ?.overallRecommendations?.description
                      }
                      gridData={integrationOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Platform" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Platform"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.platform
                          ?.controlAreaBarChart
                      }
                      donutChart={platformDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.platform
                          ?.overallRecommendations?.description
                      }
                      gridData={platformOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "API Strategy" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"API Strategy"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.apiStrategy
                          ?.controlAreaBarChart
                      }
                      donutChart={apiStrategyDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.apiStrategy
                          ?.overallRecommendations?.description
                      }
                      gridData={apiStrategyOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "Mobility" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <div className="btn-group" role="group">
                        <div className="btn-group w-100" role="group">
                          <button
                            type="button"
                            className="theme-form text-start custom-dropdown-toggle groupbtn add-proj-btn bg-white border-grey ps-2 pe-4 font-14 font-medium"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {project}
                          </button>
                          <ul className="dropdown-menu bg-white font-14 font-medium group-dropdown px-1 w-100">
                            {projectDropdownOptions()}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: mobilityPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {noData && (
                    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                      <p className="color-grey font-18 font-semibold text-center mt-5">
                        No input configurations specified to view this report
                      </p>
                      <p
                        onClick={() => {
                          navigate("/assessment", {
                            state: {
                              organizationId: reportData.organizationId,
                              verticalId: reportData.verticalId,
                              organizationName: reportData.organizationName,
                              verticalName: reportData.verticalName,
                              mode: "assess",
                            },
                          });
                        }}
                        className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                      >
                        Start Assigning Now
                        <img
                          className="ms-2"
                          src="images/blue-arrow.svg"
                          alt="arrow"
                        />
                      </p>
                    </div>
                  )}
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "User Experience" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"User Experience"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.userExperience
                          ?.controlAreaBarChart
                      }
                      donutChart={userExperienceDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.userExperience
                          ?.overallRecommendations?.description
                      }
                      gridData={userExperienceOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Performance Monitoring" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Performance Monitoring"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.performanceMonitoring?.controlAreaBarChart
                      }
                      donutChart={performanceMonitoringDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.performanceMonitoring?.overallRecommendations
                          ?.description
                      }
                      gridData={performanceMonitoringOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Codebase Architecture" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Codebase Architecture"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.codebaseArchitecture
                          ?.controlAreaBarChart
                      }
                      donutChart={codebaseArchitectureDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.codebaseArchitecture
                          ?.overallRecommendations?.description
                      }
                      gridData={codebaseArchitectureOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Device & Build Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Device & Build Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.deviceAndBuildSecurity?.controlAreaBarChart
                      }
                      donutChart={deviceandBuildSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.deviceAndBuildSecurity?.overallRecommendations
                          ?.description
                      }
                      gridData={deviceandBuildSecurityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "User & App Identity Management" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"User & App Identity Management"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[4].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaMenuReport
                            ?.userAndAppIdentityManagement?.controlAreaBarChart
                        }
                        donutChart={userandAppIdentityDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[4].value
                        }
                        overallRecom={
                          projectData?.controlAreaMenuReport
                            ?.userAndAppIdentityManagement
                            ?.overallRecommendations?.description
                        }
                        gridData={userandAppIdentityOverall}
                        gridArea={true}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "DevSecOps" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: devSecOpsPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Release Management" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Release Management"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.releaseManagement
                          ?.controlAreaBarChart
                      }
                      donutChart={releaseManagementDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.releaseManagement
                          ?.overallRecommendations?.description
                      }
                      gridData={releaseManagementOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Continuous Integration and Delivery" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Continuous Integration and Delivery"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[1].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaReport
                            ?.continuousIntegrationAndContinuousDevelopementPipelines
                            ?.controlAreaBarChart
                        }
                        donutChart={ciAndDeliveryDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[1].value
                        }
                        overallRecom={
                          projectData?.controlAreaReport
                            ?.continuousIntegrationAndContinuousDevelopementPipelines
                            ?.overallRecommendations?.description
                        }
                        gridData={ciAndDeliveryOverall}
                        gridArea={true}
                      />
                    )}
                  {controlArea === "Code Management & Version Control" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Code Management & Version Control"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[2].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaReport
                            ?.codeManagementAndVersionControl
                            ?.controlAreaBarChart
                        }
                        donutChart={codeManagementDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[2].value
                        }
                        overallRecom={
                          projectData?.controlAreaReport
                            ?.codeManagementAndVersionControl
                            ?.overallRecommendations?.description
                        }
                        gridData={codeManagementOverall}
                        gridArea={true}
                      />
                    )}
                  {controlArea === "Access Management & Privileged Control" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Access Management & Privileged Control"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[3].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaReport
                            ?.accessManagementAndPrivilegedControl
                            ?.controlAreaBarChart
                        }
                        donutChart={accessMangamentDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[3].value
                        }
                        overallRecom={
                          projectData?.controlAreaReport
                            ?.accessManagementAndPrivilegedControl
                            ?.overallRecommendations?.description
                        }
                        gridData={accessMangamentOverall}
                        gridArea={true}
                      />
                    )}
                  {controlArea === "Monitoring & Incident Response" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Monitoring & Incident Response"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[4].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaReport
                            ?.monitoringAndIncidentResponse?.controlAreaBarChart
                        }
                        donutChart={monitoringAndIncidentDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[4].value
                        }
                        overallRecom={
                          projectData?.controlAreaReport
                            ?.monitoringAndIncidentResponse
                            ?.overallRecommendations?.description
                        }
                        gridData={monitoringAndIncidentOverall}
                        gridArea={true}
                      />
                    )}
                  {controlArea === "Compliance & Governance" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Compliance & Governance"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.complianceAndGovernance
                          ?.controlAreaBarChart
                      }
                      donutChart={compilanceAndGovDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.complianceAndGovernance
                          ?.overallRecommendations?.description
                      }
                      gridData={compilanceAndGovOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "QA Processes" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: qaProcessPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Test Environment" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Test Environment"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.testEnvironment
                          ?.controlAreaBarChart
                      }
                      donutChart={testEnvironmentDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.testEnvironment
                          ?.overallRecommendations?.description
                      }
                      gridData={testEnvironmentOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Requirement Analysis" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Requirement Analysis"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.requirementAnalysis
                          ?.controlAreaBarChart
                      }
                      donutChart={requirementAnalysisDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.requirementAnalysis
                          ?.overallRecommendations?.description
                      }
                      gridData={requirementAnalysisOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Non - Functional Testing" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Non - Functional Testing"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[7].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.nonFunctionalTesting
                          ?.controlAreaBarChart
                      }
                      donutChart={nonFunctionalTestingDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[7].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.nonFunctionalTesting
                          ?.overallRecommendations?.description
                      }
                      gridData={nonFunctionalTestingOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Workflow Management" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Workflow Management"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.workFlowManagement
                          ?.controlAreaBarChart
                      }
                      donutChart={workflowManagementDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.workFlowManagement
                          ?.overallRecommendations?.description
                      }
                      gridData={workflowManagementOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Functional Testing" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Functional Testing"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.functionalTesting
                          ?.controlAreaBarChart
                      }
                      donutChart={functionalTestingDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.functionalTesting
                          ?.overallRecommendations?.description
                      }
                      gridData={functionalTestingOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Test Coverage" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Test Coverage"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.testCoverage
                          ?.controlAreaBarChart
                      }
                      donutChart={testCoverageDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.testCoverage
                          ?.overallRecommendations?.description
                      }
                      gridData={testCoverageOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Defect Validation" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Defect Validation"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.defectValidation
                          ?.controlAreaBarChart
                      }
                      donutChart={defectValidationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.defectValidation
                          ?.overallRecommendations?.description
                      }
                      gridData={defectValidationOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Test case and Test Script Development" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Test case & Test Script Development"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[3].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaReport
                            ?.testCaseAndTestScriptDevelop?.controlAreaBarChart
                        }
                        donutChart={testCaseAndTestScriptDevDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[3].value
                        }
                        overallRecom={
                          projectData?.controlAreaReport
                            ?.testCaseAndTestScriptDevelop
                            ?.overallRecommendations?.description
                        }
                        gridData={testCaseAndTestScriptDevOverall}
                        gridArea={true}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "Development Processes" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: devlopmentProcessPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Define" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Define"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.define
                          ?.controlAreaBarChart
                      }
                      donutChart={defineDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.define
                          ?.overallRecommendations?.description
                      }
                      gridData={defineOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Design" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Design"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.design
                          ?.controlAreaBarChart
                      }
                      donutChart={designDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.design
                          ?.overallRecommendations?.description
                      }
                      gridData={designOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Development" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Development"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.development
                          ?.controlAreaBarChart
                      }
                      donutChart={developmentDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.development
                          ?.overallRecommendations?.description
                      }
                      gridData={developmentOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "MS O365" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: enterpriseAppPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Compliance" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Compliance"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.compliance
                          ?.controlAreaBarChart
                      }
                      donutChart={compilanceDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.compliance
                          ?.overallRecommendations?.description
                      }
                      gridData={compilanceOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Security Audit" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Security Audit"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.securityAudit
                          ?.controlAreaBarChart
                      }
                      donutChart={securityAuditDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.securityAudit
                          ?.overallRecommendations?.description
                      }
                      gridData={securityAuditOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Site Hierarchy" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Site Hierarchy"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.siteHierarchy
                          ?.controlAreaBarChart
                      }
                      donutChart={siteHierarchyDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.siteHierarchy
                          ?.overallRecommendations?.description
                      }
                      gridData={siteHierarchyOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Custom Apps" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Custom Apps"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.customApps
                          ?.controlAreaBarChart
                      }
                      donutChart={customAppsDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.customApps
                          ?.overallRecommendations?.description
                      }
                      gridData={customAppsOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Governance" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Governance"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.governance
                          ?.controlAreaBarChart
                      }
                      donutChart={governanceDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.governance
                          ?.overallRecommendations?.description
                      }
                      gridData={governanceOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "Copilot" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: copilotReadinessPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Copilot Readiness" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Copilot Readiness"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.copilotReadiness
                          ?.controlAreaBarChart
                      }
                      donutChart={copilotReadinessDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.copilotReadiness
                          ?.overallRecommendations?.description
                      }
                      gridData={copilotReadinessOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical === "MDPW" && !loader && (
        <AwsMdpwReport
          initialReportData={{
            ...projectData,
            organizationId: reportData.organizationId,
            organizationName: reportData.organizationName,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
          }}
        />
      )}

      {vertical === "WAFR" && !loader && (
        <WafrReport
          reportData={{
            ...projectData,
            organizationId: reportData.organizationId,
            organizationName: reportData.organizationName,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
          }}
        />
      )}
      {vertical == "Infrastructure" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: infraPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {noData && (
                    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                      <p className="color-grey font-18 font-semibold text-center mt-5">
                        No input configurations specified to view this report
                      </p>
                      <p
                        onClick={() => {
                          navigate("/assessment", {
                            state: {
                              organizationId: reportData.organizationId,
                              verticalId: reportData.verticalId,
                              organizationName: reportData.organizationName,
                              verticalName: reportData.verticalName,
                              mode: "assess",
                            },
                          });
                        }}
                        className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                      >
                        Start Assigning Now
                        <img
                          className="ms-2"
                          src="images/blue-arrow.svg"
                          alt="arrow"
                        />
                      </p>
                    </div>
                  )}
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Operational Excellence" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Operational Excellence"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.operationalExcellence?.controlAreaBarChart
                      }
                      donutChart={operationalExcellenceDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.operationalExcellence?.overallRecommendations
                          ?.description
                      }
                      gridData={operationalExcellenceOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.security
                          ?.controlAreaBarChart
                      }
                      donutChart={securityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.security
                          ?.overallRecommendations?.description
                      }
                      gridData={securityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Cost Optimization" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Cost Optimization"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.costOptimization
                          ?.controlAreaBarChart
                      }
                      donutChart={costOptimizationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.costOptimization
                          ?.overallRecommendations?.description
                      }
                      gridData={costOptimizationOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Reliability" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Reliability"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.reliability
                          ?.controlAreaBarChart
                      }
                      donutChart={reliabilityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.reliability
                          ?.overallRecommendations?.description
                      }
                      gridData={reliabilityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Performance Efficiency" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Performance Efficiency"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.performanceEfficiency?.controlAreaBarChart
                      }
                      donutChart={performanceEfficiencyDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.performanceEfficiency?.overallRecommendations
                          ?.description
                      }
                      gridData={performanceEfficiencyOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {vertical === "MAP" && !loader && (
        <InfrastructureMapReport initialReportData={{
          ...projectData,
          organizationId: reportData.organizationId,
          organizationName: reportData.organizationName,
          verticalId: reportData.verticalId,
          projectId: reportData.projectId,
        }} />

      )}
      {vertical === "VMware" && !loader && (
        <VMwareReport
          initialReportData={{
            ...projectData,
            organizationId: reportData.organizationId,
            organizationName: reportData.organizationName,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
          }}
        />
      )}
      {vertical == "Data Strategy" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: dataStraPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {noData && (
                    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                      <p className="color-grey font-18 font-semibold text-center mt-5">
                        No input configurations specified to view this report
                      </p>
                      <p
                        onClick={() => {
                          navigate("/assessment", {
                            state: {
                              organizationId: reportData.organizationId,
                              verticalId: reportData.verticalId,
                              organizationName: reportData.organizationName,
                              verticalName: reportData.verticalName,
                              mode: "assess",
                            },
                          });
                        }}
                        className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                      >
                        Start Assigning Now
                        <img
                          className="ms-2"
                          src="images/blue-arrow.svg"
                          alt="arrow"
                        />
                      </p>
                    </div>
                  )}
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Transactional Database" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Transactional Database"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.transactionalDatabase?.controlAreaBarChart
                      }
                      donutChart={transactionalDatabaseDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.transactionalDatabase?.overallRecommendations
                          ?.description
                      }
                      gridData={transactionalDatabaseOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "No SQL Database" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"No SQL Database"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.noSqlDatabase
                          ?.controlAreaBarChart
                      }
                      donutChart={noSqlDatabaseDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.noSqlDatabase
                          ?.overallRecommendations?.description
                      }
                      gridData={noSqlDatabaseOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "DataLake & DataWarehouse" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"DataLake & DataWarehouse"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.dataLakeAndDataWarehouse?.controlAreaBarChart
                      }
                      donutChart={dataLakeAndDataWarehouseDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.dataLakeAndDataWarehouse?.overallRecommendations
                          ?.description
                      }
                      gridData={dataLakeAndDataWarehouseOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Reporting & Analytics" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Reporting & Analytics"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.reportingAndAnalysis
                          ?.controlAreaBarChart
                      }
                      donutChart={reportingAndAnalysisDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.reportingAndAnalysis
                          ?.overallRecommendations?.description
                      }
                      gridData={reportingAndAnalysisOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "AI & ML" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"AI & ML"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.AIAndML
                          ?.controlAreaBarChart
                      }
                      donutChart={aIAndMLDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.AIAndML
                          ?.overallRecommendations?.description
                      }
                      gridData={aIAndMLOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "Product Development Processes" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: pdpPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Proof of Concept" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Proof of Concept"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.proofOfConcept
                          ?.controlAreaBarChart
                      }
                      donutChart={proofOfConceptDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.proofOfConcept
                          ?.overallRecommendations?.description
                      }
                      gridData={proofOfConceptOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Product Design" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Product Design"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.productDesign
                          ?.controlAreaBarChart
                      }
                      donutChart={productDesignDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.productDesign
                          ?.overallRecommendations?.description
                      }
                      gridData={productDesignOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Prototyping" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Prototyping"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.prototyping
                          ?.controlAreaBarChart
                      }
                      donutChart={prototypingDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.prototyping
                          ?.overallRecommendations?.description
                      }
                      gridData={prototypingOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Product Development" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Product Development"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.productDevelopment
                          ?.controlAreaBarChart
                      }
                      donutChart={productDevelopmentDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.productDevelopment
                          ?.overallRecommendations?.description
                      }
                      gridData={productDevelopmentOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Go To Market" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Go To Market"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.goToMarket
                          ?.controlAreaBarChart
                      }
                      donutChart={goToMarketDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.goToMarket
                          ?.overallRecommendations?.description
                      }
                      gridData={goToMarketOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "IAM & Security" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: IAMPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData?.overallProficiencyIndex
                      }
                      peerScore={projectData?.peerScore}
                      controlAreaScore={projectData?.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData?.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData?.overallProficiencyIndex}
                      overallRecom={
                        projectData?.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Identity & Access Management" &&
                    !noData && (
                      <CommonReportTemp
                        overallProficiencyIndex={
                          projectData.overallProficiencyIndex
                        }
                        peerScore={projectData.peerScore}
                        controlArea={"Identity & Access Management"}
                        controlAreaScore={
                          projectData.controlAreaProficiencyIndex[0].value
                        }
                        overallHorizonatalBarChart={
                          projectData?.controlAreaMenuReport
                            ?.identityAndAccessManagement?.controlAreaBarChart
                        }
                        donutChart={identityAndAccessDonutChart}
                        gaugeChart={gaugeChart}
                        gaugeChartValue={
                          projectData.controlAreaProficiencyIndex[0].value
                        }
                        overallRecom={
                          projectData?.controlAreaMenuReport
                            ?.identityAndAccessManagement
                            ?.overallRecommendations?.description
                        }
                        gridData={identityAndAccessManagementOverall}
                        gridArea={true}
                        identityAndAccessManagementMetric={
                          projectData?.identityAndAccessManagementMetric
                        }
                        activeUsersDonutChartData={activeUsersDonutChart}
                        inActiveUsersDonutChartData={inActiveUsersDonutChart}
                        riskyUsersDonuChartData={riskyUsersDonutChart}
                        isAzureADConnected={projectData?.isAzureADConnected}
                      />
                    )}
                  {controlArea === "Email Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Email Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.emailSecurity
                          ?.controlAreaBarChart
                      }
                      donutChart={emailSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.emailSecurity
                          ?.overallRecommendations?.description
                      }
                      gridData={emailSecurityOverall}
                      gridArea={true}
                      emailSecurityMetric={projectData?.emailSecurityMetric}
                      isAzureADConnected={projectData?.isAzureADConnected}
                    />
                  )}
                  {controlArea === "Endpoint Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Endpoint Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.endpointSecurity
                          ?.controlAreaBarChart
                      }
                      donutChart={endpointSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.endpointSecurity
                          ?.overallRecommendations?.description
                      }
                      gridData={endpointSecurityOverall}
                      gridArea={true}
                      endPointSecurityMetric={
                        projectData?.endpointSecurityMetric
                      }
                      isAzureADConnected={projectData?.isAzureADConnected}
                    />
                  )}
                  {controlArea === "Application Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Application Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.applicationSecurity
                          ?.controlAreaBarChart
                      }
                      donutChart={applicationSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.applicationSecurity
                          ?.overallRecommendations?.description
                      }
                      gridData={applicationSecurityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Network Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Network Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.networkSecurity
                          ?.controlAreaBarChart
                      }
                      donutChart={networkSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.networkSecurity
                          ?.overallRecommendations?.description
                      }
                      gridData={networkSecurityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Data Protection" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Data Protection"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport?.dataProtection
                          ?.controlAreaBarChart
                      }
                      donutChart={dataProtectionDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[5].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport?.dataProtection
                          ?.overallRecommendations?.description
                      }
                      gridData={dataProtectionOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Operational Risk Management" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Operational Risk Management"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaMenuReport
                          ?.operationalRiskManagement?.controlAreaBarChart
                      }
                      donutChart={operationalRiskManagementDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[6].value
                      }
                      overallRecom={
                        projectData?.controlAreaMenuReport
                          ?.operationalRiskManagement?.overallRecommendations
                          ?.description
                      }
                      gridData={operationalRiskManagementOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "ServiceNow" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: serviceNowPreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {/* {bindButton()} */}
                        <>
                          <button
                            className={`nav-link text-start rounded-0 font-medium font-14 sub-acrd collapsed d-flex align-items-center ${
                              controlArea === "Process" ? "active" : ""
                              }`}
                            data-bs-toggle="collapse"
                            data-bs-target="#servicenow-sub"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-performEff"
                            aria-selected={controlArea === "Process"}
                            onClick={() => {
                              setControlArea("Process");
                            }}
                          >
                            Process{" "}
                            <img
                              src="images/sub-acrd-arrow.svg"
                              className="sub-acrd-arrow ms-2 "
                              alt="sub-acrd-arrow"
                            />
                          </button>
                          <div
                            id="servicenow-sub"
                            className={`collapse ${
                                controlArea === "Process" ||
                                controlArea === "CSM" ||
                                controlArea === "HRSD" ||
                                controlArea === "IRM" ||
                                controlArea === "ITAM" ||
                                controlArea === "ITOM" ||
                                controlArea === "ITSM" ||
                                controlArea === "Portal" ||
                                controlArea === "SecOps" ||
                                controlArea === "SPM"
                                ? "show"
                                : ""
                              }`}
                          >
                            <div
                              className="nav flex-column nav-pills custom-v-pills w-100"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              {/* {bindProcessForServiceNow()} */}
                              {projectData?.processControlAreaNames
                                ?.CSMControlAreaName == "CSM" && (
                                  <button
                                    className={
                                      controlArea == "CSM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("CSM");
                                    }}
                                  >
                                    CSM
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.HRSDControlAreaName == "HRSD" && (
                                  <button
                                    className={
                                      controlArea == "HRSD"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("HRSD");
                                    }}
                                  >
                                    HRSD
                                  </button>
                                )}

                              {projectData?.processControlAreaNames
                                ?.IRMControlAreaName == "IRM" && (
                                  <button
                                    className={
                                      controlArea == "IRM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("IRM");
                                    }}
                                  >
                                    IRM
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.ITAMControlAreaName == "ITAM" && (
                                  <button
                                    className={
                                      controlArea == "ITAM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("ITAM");
                                    }}
                                  >
                                    ITAM
                                  </button>
                                )}

                              {projectData?.processControlAreaNames
                                ?.ITOMControlAreaName == "ITOM" && (
                                  <button
                                    className={
                                      controlArea == "ITOM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("ITOM");
                                    }}
                                  >
                                    ITOM
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.ITSMControlAreaName == "ITSM" && (
                                  <button
                                    className={
                                      controlArea == "ITSM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("ITSM");
                                    }}
                                  >
                                    ITSM
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.PortalControlAreaName == "Portal" && (
                                  <button
                                    className={
                                      controlArea == "Portal"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("Portal");
                                    }}
                                  >
                                    Portal
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.SecOpsControlAreaName == "SecOps" && (
                                  <button
                                    className={
                                      controlArea == "SecOps"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("SecOps");
                                    }}
                                  >
                                    SecOps
                                  </button>
                                )}
                              {projectData?.processControlAreaNames
                                ?.SPMControlAreaName == "SPM" && (
                                  <button
                                    className={
                                      controlArea == "SPM"
                                        ? "nav-link text-start rounded-0 font-medium font-14 active"
                                        : "nav-link text-start rounded-0 font-medium font-14 "
                                    }
                                    id="v-pills-overallSumm-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-overallSumm"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-overallSumm"
                                    aria-selected="true"
                                    onClick={() => {
                                      setControlArea("SPM");
                                    }}
                                  >
                                    SPM
                                  </button>
                                )}
                            </div>
                          </div>
                          {bindButton()}
                          {/* <button
                            className={
                              controlArea == "Environmental Detail"
                                ? "nav-link text-start rounded-0 font-medium font-14 active"
                                : "nav-link text-start rounded-0 font-medium font-14"
                            }
                            id="v-pills-security-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-security"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-security"
                            aria-selected="true"
                            onClick={() => {
                              setControlArea("Environmental Detail");
                            }}
                          >
                            Environmental Detail
                          </button> */}
                        </>
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length + 1}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "Process" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Process"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.process
                          ?.controlAreaBarChart
                      }
                      donutChart={serviceNowProcessDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[4].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.process
                          ?.overallRecommendations?.description
                      }
                      gridData={serviceNowProcessOverall}
                      gridArea={false}
                    />
                  )}
                  {controlArea === "CSM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"CSM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.CSMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.CSM?.controlAreaBarChart
                      }
                      donutChart={CSMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.CSMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.CSM
                          ?.overallRecommendations?.description
                      }
                      gridData={CSMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "HRSD" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"HRSD"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.HRSDProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.HRSD
                          ?.controlAreaBarChart
                      }
                      donutChart={HRSDDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.HRSDProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.HRSD
                          ?.overallRecommendations?.description
                      }
                      gridData={HRSDOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "IRM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"IRM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.IRMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.IRM?.controlAreaBarChart
                      }
                      donutChart={IRMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.IRMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.IRM
                          ?.overallRecommendations?.description
                      }
                      gridData={IRMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "ITAM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"ITAM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITAMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.ITAM
                          ?.controlAreaBarChart
                      }
                      donutChart={ITAMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITAMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.ITAM
                          ?.overallRecommendations?.description
                      }
                      gridData={ITAMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "ITOM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"ITOM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITOMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.ITOM
                          ?.controlAreaBarChart
                      }
                      donutChart={ITOMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITOMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.ITOM
                          ?.overallRecommendations?.description
                      }
                      gridData={ITOMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "ITSM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"ITSM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITSMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.ITSM
                          ?.controlAreaBarChart
                      }
                      donutChart={ITSMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.ITSMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.ITSM
                          ?.overallRecommendations?.description
                      }
                      gridData={ITSMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Portal" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Portal"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.PortalProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.portal
                          ?.controlAreaBarChart
                      }
                      donutChart={portalDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.PortalProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.portal
                          ?.overallRecommendations?.description
                      }
                      gridData={portalOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "SecOps" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"SecOps"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.SecOpsProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.secOps
                          ?.controlAreaBarChart
                      }
                      donutChart={secOpsDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.SecOpsProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.secOps
                          ?.overallRecommendations?.description
                      }
                      gridData={secOpsOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "SPM" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"SPM"}
                      controlAreaScore={
                        projectData.processControlAreaProficiencyIndex
                          ?.SPMProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.spm?.controlAreaBarChart
                      }
                      donutChart={SPMDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.processControlAreaProficiencyIndex
                          ?.SPMProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.spm
                          ?.overallRecommendations?.description
                      }
                      gridData={SPMOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Environmental Detail" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Environmental Detail"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.environmentDetails
                          ?.controlAreaBarChart
                      }
                      donutChart={serviceNowEnvironmentDetailsDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[0].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.environmentDetails
                          ?.overallRecommendations?.description
                      }
                      gridData={serviceNowEnvironmentDetailsOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Security"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.security
                          ?.controlAreaBarChart
                      }
                      donutChart={serviceNowSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[1].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.security
                          ?.overallRecommendations?.description
                      }
                      gridData={serviceNowSecurityOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Automation" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Automation"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.automation
                          ?.controlAreaBarChart
                      }
                      donutChart={serviceNowAutomationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[2].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.automation
                          ?.overallRecommendations?.description
                      }
                      gridData={serviceNowAutomationOverall}
                      gridArea={true}
                    />
                  )}
                  {controlArea === "Integration/Customization" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Integration/Customization"}
                      controlAreaScore={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.integrationCustomization
                          ?.controlAreaBarChart
                      }
                      donutChart={
                        serviceNowIntegrationAndCustomizationDonutChart
                      }
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.controlAreaProficiencyIndex[3].value
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.integrationCustomization
                          ?.overallRecommendations?.description
                      }
                      gridData={serviceNowIntegrationAndCustomizationOverall}
                      gridArea={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical == "Salesforce" && !loader && (
        <div>
          <div className="container-fluid" style={{ marginTop: "105px" }}>
            <div className="row justify-content-center">
              <div className="px-4">
                {/* heading content starts here */}
                <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-3">
                  <div className="d-flex align-items-center mb-4 mb-md-0">
                    <a href="#">
                      <img
                        src="images/backarrow.svg"
                        alt="back-arrow"
                        title="Back"
                        onClick={() => navigate("/homepage")}
                      />
                    </a>
                    <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                      Assessment Summary
                    </h2>
                  </div>
                  <div className="d-md-flex d-grid gap-3 ">
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                      <button
                        onClick={() => {
                          navigate("/previewpage", {
                            state: salesforcePreviewReportData,
                          });
                        }}
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                      >
                        <img
                          className="me-2"
                          src="images/download-white.svg"
                          alt="download-icon"
                        />
                        Download Report
                      </button>
                    </div>
                  </div>
                </div>
                {/* heading content ends here */}
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                    <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={
                            controlArea == "Overall Summary"
                              ? "nav-link text-start rounded-0 font-regular font-14 active"
                              : "nav-link text-start rounded-0 font-regular font-14"
                          }
                          id="v-pills-overallSumm-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-overallSumm"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-overallSumm"
                          aria-selected="true"
                          onClick={() => {
                            setControlArea("Overall Summary");
                          }}
                        >
                          Overall Summary
                        </button>
                        <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">
                          Control Areas
                        </p>
                        {bindButton()}
                      </div>
                    </div>
                    <select
                      onChange={(event) => {
                        setControlArea(event.target.value);
                      }}
                      className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3"
                    >
                      <option selected>Overall Summary</option>
                      {bindDropdownButtons()}
                    </select>
                  </div>
                  {controlArea === "Overall Summary" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlAreaScore={projectData.controlAreaNames.length}
                      overallHorizonatalBarChart={
                        projectData.controlAreaProficiencyIndex
                      }
                      donutChart={donutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={projectData.overallProficiencyIndex}
                      overallRecom={
                        projectData.overallRecommendations.description
                      }
                      gridData={sortedData}
                      gridArea={false}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.overallCloudPerformanceInsights
                      }
                    />
                  )}
                  {controlArea === "Business Process" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Business Process"}
                      controlAreaScore={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.businessProcessProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.businessProcess
                          ?.controlAreaBarChart
                      }
                      donutChart={businessProcessDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.businessProcessProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.businessProcess
                          ?.overallRecommendations?.description
                      }
                      gridData={businessProcessOverall}
                      gridArea={true}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.controlAreaReport?.businessProcess
                          ?.cloudPerformanceInsights
                      }
                    />
                  )}
                  {controlArea === "Automation" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Automation"}
                      controlAreaScore={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.automationProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.automation
                          ?.controlAreaBarChart
                      }
                      donutChart={automationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.automationProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.automation
                          ?.overallRecommendations?.description
                      }
                      gridData={automationOverall}
                      gridArea={true}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.controlAreaReport?.automation
                          ?.cloudPerformanceInsights
                      }
                    />
                  )}
                  {controlArea === "Environment Details" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Environment Details"}
                      controlAreaScore={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.environmentDetailsProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.environmentDetails
                          ?.controlAreaBarChart
                      }
                      donutChart={environmentDetailsDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.environmentDetailsProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.environmentDetails
                          ?.overallRecommendations?.description
                      }
                      gridData={environmentDetailsOverall}
                      gridArea={true}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.controlAreaReport?.environmentDetails
                          ?.cloudPerformanceInsights
                      }
                    />
                  )}
                  {controlArea === "Security" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Security"}
                      controlAreaScore={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.securityProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport?.security
                          ?.controlAreaBarChart
                      }
                      donutChart={salesforceSecurityDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.securityProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport?.security
                          ?.overallRecommendations?.description
                      }
                      gridData={salesforceSecurityOverall}
                      gridArea={true}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.controlAreaReport?.security
                          ?.cloudPerformanceInsights
                      }
                    />
                  )}
                  {controlArea === "Integration & Customization" && !noData && (
                    <CommonReportTemp
                      overallProficiencyIndex={
                        projectData.overallProficiencyIndex
                      }
                      peerScore={projectData.peerScore}
                      controlArea={"Integration & Customization"}
                      controlAreaScore={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.integrationCustomizationProficiencyIndex
                      }
                      overallHorizonatalBarChart={
                        projectData?.controlAreaReport
                          ?.integrationAndCustomization?.controlAreaBarChart
                      }
                      donutChart={integrationAndCustomizationDonutChart}
                      gaugeChart={gaugeChart}
                      gaugeChartValue={
                        projectData.selectedControlAreaProficiencyIndex
                          ?.integrationCustomizationProficiencyIndex
                      }
                      overallRecom={
                        projectData?.controlAreaReport
                          ?.integrationAndCustomization?.overallRecommendations
                          ?.description
                      }
                      gridData={integrationAndCustomizationOverall}
                      gridArea={true}
                      vertical={"Salesforce"}
                      cloudPerformanceInsights={
                        projectData?.controlAreaReport
                          ?.integrationAndCustomization
                          ?.cloudPerformanceInsights
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/**Assessment Summary verticals end*/}
      {/**loader popup */}
      {loader ? (
        <div
          className="modal fade show"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
          id="progress"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <h5 className="font-bold mb-0 font-20 font-semibold d-flex justify-content-between align-items-center">
                  Progress
                </h5>
              </div>
              <div className="modal-body">
                {/* <div
                  className="progress rounded-3 mb-3 mt-4 mb-5"
                  style={{ height: 10 }}
                >
                  <div
                    className="progress-bar progress-bar-bg rounded-3"
                    role="progressbar"
                    style={{ width: `${percentage}%` }}
                    aria-valuenow={percentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div> */}
                <div className="loader-container">
                  <div className="loaderBar"></div>
                  <p
                    style={{ textAlign: "center" }}
                    className="font-19 font-semibold color-grey mt-4"
                  >
                    {!noProject
                      ? "Kindly wait while the report is being generated."
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/**loader popup end*/}
      {/**Delete Popup */}
      <div
        className="modal fade"
        id="delete"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3">
            <div className="modal-header pb-0 border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="w-100 text-center mb-3">
                <img src="images/delete-icon.svg" alt="delete" />
              </div>

              <p className="font-semibold font-24 text-center mb-2 red-400">
                Delete Confirmation
              </p>

              <p className="font-semibold font-16 text-center mb-5">
                Do you really want to delete the Project?
              </p>

              <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                <a
                  className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    deleteDropDownOption(selectedProjectId);
                  }}
                >
                  Yes
                </a>

                <button
                  type="button"
                  className="btn btn-danger red-400 btn-lg px-4  text-white font-14 font-semibold "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**Delete Popup end */}
      {/**project selection page */}
      {noProject && (
        <div style={{ marginTop: "105px", padding: "20px" }}>
          <BreadCrumbs data={projectSelectBreadCrumbData}></BreadCrumbs>
          <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-4">
            <div className="d-flex align-items-center mb-4 mb-md-0">
              <a href="#">
                <img
                  src="images/backarrow.svg"
                  alt="back-arrow"
                  title="Back"
                  onClick={() => navigate("/homepage")}
                />
              </a>
              <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                Assesment Summary
              </h2>
            </div>
            <div className="d-md-flex d-grid gap-3 ">
              <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                <div className="btn-group" role="group">
                  <div className="btn-group w-100" role="group">
                    <button
                      type="button"
                      className="theme-form text-start custom-dropdown-toggle groupbtn add-proj-btn bg-white border-grey ps-2 pe-4 font-14 font-medium"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {project ? project : "Select any Project"}
                    </button>
                    <ul className="dropdown-menu bg-white font-14 font-medium group-dropdown px-1 w-100">
                      {projectDropdownOptions()}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                <button
                  disabled
                  onClick={() => {
                    navigate("/previewpage", {
                      state: appDevPreviewReportData,
                    });
                  }}
                  type="button"
                  className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                >
                  <img
                    className="me-2"
                    src="images/download-white.svg"
                    alt="download-icon"
                  />
                  Download Report
                </button>
              </div>
            </div>
          </div>
          <div>
            <center>
              {noData && (
                <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                  <p className="color-grey font-18 font-semibold text-center mt-5">
                    No input configurations specified to view this report
                  </p>
                  <p
                    onClick={() => {
                      navigate("/assessment", {
                        state: {
                          organizationId: reportData.organizationId,
                          verticalId: reportData.verticalId,
                          organizationName: reportData.organizationName,
                          verticalName: reportData.verticalName,
                          mode: "assess",
                        },
                      });
                    }}
                    className="link-blue cursor-pointer font-16 font-semibold text-center mt-5"
                  >
                    Start Assigning Now
                    <img
                      className="ms-2"
                      src="images/blue-arrow.svg"
                      alt="arrow"
                    />
                  </p>
                </div>
              )}
              {!noData && (
                <span style={{ color: "grey" }}>
                  Choose any project to view its report.
                </span>
              )}
            </center>
          </div>
        </div>
      )}
      {/**project selection page end */}
      {/**toaster */}
      {hideToast ? <></> : <ToastComponent name={toastProp} />}
    </>
  );
};

export default GenReport;
